import React from "react";
import "./style.css";

export default function Input(props) {
  const inputProps = {};
  if (props.type === "date") {
    if (props.min) inputProps.min = props.min;
    if (props.max) inputProps.max = props.max;
  }

  const handleInput = (e) => {
    if (props.apenasNumeros) {
      e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }
    if (props.onInput) {
      props.onInput(e);
    }
  };

  return (
    <div 
      className="form__group field"
      style={{
        width: props.widthDiv,
        marginLeft: props.marginleftDiv,
        marginRight: props.marginrightDiv,
      }}
    >
      <input
        type={props.type}
        className="form__field"
        placeholder={props.placeholder}
        required={props.required === "true" || props.required === true}
        value={props.value}
        disabled={props.disabled}
        onChange={props.event}
        onInput={handleInput}
        pattern={props.pattern}
        maxLength={props.maxLength}
        inputMode={props.apenasNumeros ? "numeric" : props.inputMode}
        style={{
          color: props.color,
          width: props.width,
          height: props.height,
          marginLeft: props.marginleft,
          marginRight: props.marginright,
          marginBottom: props.marginbottom,
          marginTop: props.margintop,
          border: props.border,
          borderRadius: props.borderradius,
          alignItems: props.alignitems,
        }}
        {...inputProps}
      />
      <label
        htmlFor={props.placeholder}
        style={{ color: props.placeholdercolor }}
        className="form__label"
      >
        {props.placeholder}
      </label>
    </div>
  );
}