import React from 'react'
import "./style.css"

function Loading({ color }) {
    return (
        <div
            className='loading'
            style={{
                '--_g': color ? `no-repeat radial-gradient(farthest-side, ${color} 94%, #0000)` : undefined // Ajusta o gradiente se `color` for fornecido
            }}
        />

    )
}

export default Loading
