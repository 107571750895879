import { FaFileImport } from "react-icons/fa";
import { HiTrash } from "react-icons/hi";
import styled from "styled-components";


export const Background = styled.div`
width: 100vw;
height: 100vh;
display: flex;
position: fixed;
align-items: center;
 justify-content: center;
background-color: rgba(0,0,0,0.35);
z-index: 10;
top: 0;
/* border: 15px solid black; */
`

export const Cardo = styled.div`
${({ $escolha }) => $escolha == null ? `
  width: 45rem;
  height: 60vh;
  `: `
  width: 80vw;
  max-width: 55rem;
  min-width: 25rem;
  height: 40rem;
`}
max-height: 90vh;
display: flex;
align-items: center;
flex-direction: column;
background-color: white;
border: 1px solid black;
border-radius: 20px;
padding: 20px 30px;

@media (max-width: 1300px) {
  height: 60vh;
  min-width: 10rem;
}
@media (max-width: 800px) {
  padding: 10px;
}
`
export const DivSave = styled.div`
height: 5rem;
width: 100%;
display: flex;
justify-content: space-evenly;
align-items: center;
padding: 10px 0;
border-left: 5px solid #1a2e35;
border-right: 5px solid #1a2e35;
border-bottom: 5px solid #1a2e35;
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
gap: 10%;

@media (max-width: 1000px) {
  border-left: 2px solid #1a2e35;
  border-right: 2px solid #1a2e35;
  border-bottom: 2px solid #1a2e35;
}
`

export const DivMain = styled.div`
${({ $escolha }) => $escolha == 2 ? `
  justify-content: center;
  align-items: flex-start;
  height: 30rem;
  `: `
  height: 100%;
  justify-content: center;
  align-items: center;
`}
  display: flex;
  width: 100%;
  border: 5px solid #1a2e35;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
  overflow-y: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  @media (max-width: 1000px) {
    border: 2px solid #1a2e35;
  }
`
export const DivTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: var(--background);
  background-color: #1a2e35;
  white-space: nowrap;
  font-weight: 400;
  border-radius: 20px 20px 0px 0px;
  border-right: 5px solid #1a2e35;
  border-left: 5px solid #1a2e35;

  @media (max-width: 1000px) {
  border-right: 2px solid #1a2e35;
  border-left: 2px solid #1a2e35;
  }
`

export const Title = styled.h1`
  padding: 15px 50px 15px 30px;

  @media (max-width: 1000px) {
    padding: 10px;
    font-size: 1.5rem;
  }
  @media (max-width: 1000px) {
    padding: 10px;
    font-size: 1rem;
  }
`

export const DivText = styled.h2`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items:center;
`

export const DivText2 = styled.h2`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items:center;
`
export const ContainerTable = styled.div`
  width: 100%;
  min-height: 70%;
  overflow-x: hidden;
  overflow-y: auto;
  border: 5px solid #1a2e35;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &::-webkit-scrollbar {
    width: 0px;
  } 
`
export const Table = styled.table`
  border-collapse: collapse;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 0.9em;
  width: 100%;
  height: fit-content;
`

export const Theader = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: 0;
  height: 6vh;
  font-size: 1rem;
  z-index: 9;
`
export const Theader2 = styled.thead`
  background-color: #1a2e35;
  color: #ffffff;
  text-align: center;
  position: sticky;
  top: -1px;
  height: 8vh;
  font-size: 1rem;
  z-index: 9;
`

export const Th = styled.th`
padding: 2px 10px;
text-align: center;
`

export const ThRm = styled.th`
  padding: 2px 20px;
  text-align: center;
`;

export const ThNome = styled.th`
  padding: 2px 15px;
  text-align: center;
`;

export const Td = styled.td`
padding: 5px 30px;
min-height: 20vh;
text-align: center;
`

export const Tbody = styled.tbody`
height: fit-content;
overflow: scroll;
`

export const Trbody = styled.tr`
border-bottom: 1px solid #dddddd;
height: 40px;
&:nth-of-type(even) {
  background-color: #f1f1f1;
}

transition: all 0.2s;

&:hover {
  background-color: #bdbdbd;
  /* color: white; */
  transform: scale(1.01);
}
`
export const FormDiv = styled.form`
display: flex;
width: 100%;
align-items: center;
justify-content: center;
gap: 3em;
padding-top: 2%;
padding-bottom: 2%;
border-right: 5px solid #1a2e35;
border-left: 5px solid #1a2e35;
`

export const DragAndDropArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.2s ease;

  ${({ dragActive }) =>
    dragActive
      ? `
    background-color: #e0f7fa;
    border-color: #00acc1;
  `
      : ""}
`;

export const DragAndDropArea2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 3rem; */
  gap: 3em;
  border: 2px dashed #ccc;
  border-radius: 10px;
  padding: 15px 60px;
  text-align: center;
  position: relative;
  margin-top: 10px;
  transition: background-color 0.2s ease;

  ${({ dragActive }) =>
    dragActive
      ? `
    background-color: #e0f7fa;
    border-color: #00acc1;
  `
      : ""}
    
    @media (max-width: 1000px) {
      width: 60vw;
      padding: 5px;
      gap: 0.5em;
    }
`;

export const HiddenFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const Instructions = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 70%;
  gap: 10px;
  min-width: 25rem;
  font-size: 1rem;
  font-weight: 600;
  color: #7c7c7c;

  @media (max-width: 800px) {
    min-width: 15rem;
  }
`;

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 1.4rem;
  color: #000;
  
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  span{
    font-weight: 700;
    text-transform: capitalize;
  }
    
  @media (max-width: 1200px) {
    width: 100%;
    font-size: 13px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    font-size: 10px;
  }
`;

export const FileDetails = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #000;
  
  @media (max-width: 1200px) {
    width: 100%;
    font-size: 13px;
  }
  @media (max-width: 1000px) {
    width: 100%;
    font-size: 10px;
  }
`;

export const PdfViewer = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  
`;

export const DivCard = styled.div`
  width: 100%;
  max-width: 790px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    justify-content: space-around;
  }
`;

// export const Card = styled.div`
//   display: flex;
//   width: 200px;
//   height: 200px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   position: relative;  /* Mudar para relative */
//   border: 2px solid #000;
//   border-radius: 10px;
//   overflow: hidden;  /* Para esconder o ícone se necessário */
//   /* padding: 10px; */
//   box-shadow: 10px 10px 10px  #00000050;

//   .icon {
//     color: #0c44be;
//     position: absolute;
//     /* top: 0;  //Alinha o ícone no topo */
//     /* left: 0;  //Alinha o ícone à esquerda */
//     /* width: 100%;  //Preenche toda a largura do card */
//     height: 100%;  //Preenche toda a altura do card
//     font-size: 18rem;
//     display: flex;
//     justify-content: center;  // Centraliza o ícone
//     align-items: center;  // Centraliza o ícone
//     opacity: 0.4;  // Ajusta a opacidade do ícone, se necessário 
//     z-index: 1;
//   }

//   .content {
//     width: 100%;
//     height: 100%;
//     font-weight: 700;
//     position: relative;  /* Garante que o conteúdo fique acima do ícone */
//     z-index: 2;  /* Certifica que o conteúdo apareça acima do ícone */
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: space-between;
//     text-transform: capitalize;
//     gap: 10px;
//     backdrop-filter: blur(2px); /* Efeito de desfoque no fundo */
//     background: rgba(255, 255, 255, 0.2); /* Fundo semi-transparente */
//     border-radius: 10px; /* Adiciona borda arredondada ao fundo */
//     padding: 10px; /* Adiciona um pouco de preenchimento */

//     span {
//       width: 90%;
//       overflow-wrap: break-word; /* Permite quebrar palavras longas */
//       word-wrap: break-word; /* Compatibilidade com navegadores mais antigos */
//       hyphens: auto; /* Hifeniza palavras, se necessário */
//     }
//   }
// `;

export const Card = styled.div`
  width: 250px;
  height: fit-content;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 10px 10px 10px  #00000050;
  
  .DivIcon {
    width: 100%;
    height: auto; 
    min-height: 50%;  
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon { 
      display: flex;
      width: 30%;
      height: 100%;
      justify-content: center;  
      align-items: center; 
      color: #0c44be;
    }
  }

  span {
    width: 100%;
    height: auto;
    min-height: 35px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-wrap: wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    border-right: 2px solid #000;
    padding: 10px;
    font-weight: 700;
    overflow: hidden;
  }

  .DivButton {
    display: flex;
    justify-content: space-around;
    width: 100%;
    border-top: 2px solid #000;
    border-radius: 0 0 10px 10px;
    padding: 10px 0;
    gap: 5px;
  }
`;

export const DivideTable = styled.div`
  width: 100%;
  height: 4rem;
  display: flex;
  background-color: #1a2e35;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #ffffff;
  gap: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 4px;
`;

export const Infos = styled.div`
  width: 100%;
  display: flex;
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: capitalize;
  justify-content: space-around;
  align-items: center;
`;

export const CursoTurmaDiscInfo = styled.div`
  justify-content: center;
  align-items: center;

  p {
  font-size: 0.9rem;
  font-weight: 100;
  text-transform: capitalize;
}
`;

export const Grup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2rem;
  width: fit-content;

  ::placeholder {
    color: #ffffff;
  }
`;