import React, { useState } from "react"
import { Buttons, ButtonsAtt, Card, Contanier, Forms, Header, Title } from "./style"
import Input from "../../Input"
import Button from "../../Button"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import { useNavigate } from "react-router-dom"

export default function RecuperarSenha({ isActived, setIsActived, perfil }) {
  const nav = useNavigate()
  const [senha, setSenha] = useState("")
  const [senhaNova, setSenhaNova] = useState("")
  const [senhaConfirmar, setSenhaConfirmar] = useState("")
  const token = localStorage.getItem("token")
  
  async function submitEvent() {
    try {
      if (senhaNova == senhaConfirmar) {
        const data = {
          senha_antiga: senha,
          senha_nova: senhaNova,
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        }

        const config = {
          headers: headers,
        }
        let response;

        if (perfil == 'aluno') {
          response = await api.put("/aluno/atualizar-minha-senha", { data }, config)
        } else {
          response = await api.put("/usuario/atualizar-minha-senha", { data }, config)
        }
        if (response.status == 200) {
          setIsActived(false)
          Alert("Sucesso", response.data.mensagem || "Senha alterada com sucesso!")
          nav('/')
        } else {
          Alert("Erro", response.data.erro || "Não foi possível alterar a senha")
        }
      } else {
        Alert("Erro", "Senhas diferentes, digite novamente a senha ")
      }
    } catch (erro) {
      console.log(erro)
      Alert("Erro", erro.response.data.erro || "Não foi possível alterar a senha")
    }
  }
  if (isActived == true) {
    return (
      <Contanier>
        <Card>
          <Header>
            <Title>Atualizar Senha</Title>
            <Buttons
              onClick={() => {
                setIsActived(false)
              }}
            >
              <Icone name={"X"} color={" #3a3f86"} size={36} />
            </Buttons>
          </Header>
          <Forms>
            <Input
              width="100%"
              color="black"
              type={"password"}
              placeholdercolor="black"
              placeholder="Senha Atual"
              required={"true"}
              event={(e) => {
                setSenha(e.target.value)
              }}
            />
            <Input
              width="100%"
              placeholdercolor="black"
              color="black"
              type={"password"}
              placeholder={"Senha Nova"}
              required={"true"}
              event={(e) => {
                setSenhaNova(e.target.value)
              }}
            />
            <Input
              width="100%"
              color="black"
              placeholdercolor="black"
              type={"password"}
              placeholder={"Confirmar Senha"}
              required={"true"}
              event={(e) => {
                setSenhaConfirmar(e.target.value)
              }}
            />
          </Forms>
          <ButtonsAtt>
            <Button
              id={"button"}
              width="50%"
              height="100%"
              event={() => {
                submitEvent()
              }}
              placeholder="Atualizar"
            />
          </ButtonsAtt>
        </Card>
      </Contanier>
    )
  } else if (isActived == false) {
    return null
  }
}
