import UserDefault from "../images/user.png"
import Url_Imagem from "../json/texts.json"

function Url_Image(RM) {
    return (
        window.location.href.includes("localhost") || window.location.href.includes("10") ? `${Url_Imagem.STATIC_URL_IMAGE_DEV}${btoa(RM)}` :
        window.location.href.includes("testecart.fiecdev") ? `${Url_Imagem.STATIC_URL_IMAGE_TESTE}${btoa(RM)}` :
          window.location.href.includes("alunos.fiecdev") ? `${Url_Imagem.STATIC_URL_IMAGE_PROD}${btoa(RM)}` : UserDefault
    )
}

export default Url_Image
