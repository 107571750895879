import React, { useState } from 'react'
import Button from '../../Components/Button'
import jwt_decode from "jwt-decode"
import { useNavigate } from 'react-router-dom'
import { Container, LeftDiv, RightDiv, Titulo, Card, TitleHolder } from './style'
import User from "../../assets/images/user.png"
import RecuperarSenha from '../../Components/modals/RecuperarSenha'

export default function ProfessorHome() {
  const [isPasswordResetActive, setIsPasswordResetActive] = useState(false);
  const Navigate = useNavigate()
  const token = localStorage.getItem("token")
  const decoded = jwt_decode(token)

  return (
    <Container>
      <RecuperarSenha isActived={isPasswordResetActive} setIsActived={setIsPasswordResetActive} perfil={'professor'} />
      <LeftDiv>
        <Card>
          <TitleHolder>
            <Titulo>Professor(a)</Titulo>
          </TitleHolder>
          <div style={{ width: "fit-content" }}>
            <img src={User} alt="perfil" className="pefil" />
          </div>
          <TitleHolder>
            <Titulo>{decoded.nome}</Titulo>
          </TitleHolder>
        </Card>
      </LeftDiv>
      <RightDiv>
        <Button
          width="250px"
          height="55px"
          placeholder="Progressão Parcial"
          event={() => Navigate("/progressao-parcial-professor")}
        />
        <Button
          width="250px"
          height="55px"
          placeholder="Atualizar Senha"
          event={() => setIsPasswordResetActive(true)}
        />
        <Button
          width="250px"
          height="55px"
          placeholder="Sair"
          event={() => Navigate("/")}
        />
      </RightDiv>
    </Container>
  )

}