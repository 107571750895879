import React, { useEffect, useState } from "react"
import { Buttons, Card, Contanier, DivButtom, Forms, Header, Title } from "./style"
import Input from "../../Input"
import Button from "../../Button"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import Swal from "sweetalert2"
import InputSelect from "../../Select"

export default function CriarUsario({ isActived, setIsActived }) {
  const [Nome, setNome] = useState("")
  const [Matricula, setMatricula] = useState("")
  const [Sobrenome, setSobrenome] = useState("")
  const [Email, setEmail] = useState("")
  const [Senha, setSenha] = useState("")
  const [Perfil, setPerfil] = useState("")
  const optionsPerfil = [
    { label: "-", value: null },
    { label: "secretaria", value: 'secretaria' },
    { label: "coordenador", value: 'coordenador' },
    { label: "professor", value: 'professor' }
  ]

  const data = {
    email: Email,
    nome: Nome,
    sobrenome: Sobrenome,
    senha: Senha,
    perfil: Perfil,
    matricula: Matricula,
  }
  function setAllFalse() {
    setNome("")
    setEmail("")
    setSenha("")
    setPerfil("")
  }

  useEffect(() => {
    if (['coordenador', 'professor'].includes(Perfil)) {
      setSenha("")
    }
  }, [Senha, Perfil])


  function HandleManualSubmit(e) {
    const isValidEmail = data.email.includes("@") && data.email.includes(".");
    const isProfessorOrCoordenador = ['coordenador', 'professor'].includes(Perfil);
    const isValidSobrenome = data.senha && !isProfessorOrCoordenador || (!data.senha && isProfessorOrCoordenador && data.sobrenome && data.matricula);

    if (
      isValidEmail &&
      data.nome &&
      isValidSobrenome &&
      data.perfil != null &&
      data.perfil != undefined &&
      data.perfil != ''
    ) {
      api
        .post("/usuario/cadastrar", { data })
        .then((res) => {
          Swal.fire({
            title: "Concluido",
            text: `${res.data.mensagem}`,
            icon: "success",
            iconColor: "green",
            confirmButtonColor: "var(--azul)",
            confirmButtonText: "Confirmar",
          })
          setAllFalse()
          setIsActived(false)
          window.location.reload()
        })
        .catch((err) => {
          Swal.fire({
            title: "Erro",
            text: err?.response?.data?.erro.split('..')[0] || "erro ao cadastrar usuario",
            icon: "error",
            iconColor: "red",
            confirmButtonColor: "var(--azul)",
            confirmButtonText: "Confirmar",
          })
        })
    } else {
      Alert("Erro", "Por favor, preencha todos os campos obrigatórios corretamente.")
    }
  }

  if (isActived == true) {
    return (
      <Contanier>
        <Card>
          <Header>
            <Title>Criação de Usuario</Title>
            <Buttons
              onClick={() => {
                setIsActived(false)
              }}
            >
              <Icone name={"X"} color={" #3a3f86"} size={36} />
            </Buttons>
          </Header>
          <Forms>
            <InputSelect
              Style1={{ color: '#000' }}
              Style2={{ width: "30vh", marginBottom: '-1.5rem' }}
              Label={"Perfil"}
              Options={optionsPerfil}
              Default={Perfil ? Perfil : "-"}
              OnChange={(e) => setPerfil(e.target.value)}
            />
            <Input
              width="30vh"
              color="black"
              type={"text"}
              placeholdercolor="black"
              placeholder="Nome"
              required={"true"}
              event={(e) => {
                setNome(e.target.value)
              }}
            />
            {['coordenador', 'professor'].includes(Perfil) ? (
              <>
                <Input
                  width="30vh"
                  color="black"
                  type={"text"}
                  placeholdercolor="black"
                  placeholder="Sobrenome"
                  required={"true"}
                  event={(e) => {
                    setSobrenome(e.target.value)
                  }}
                />
                <Input
                  width="30vh"
                  color="black"
                  type="text"
                  placeholdercolor="black"
                  placeholder="Matrícula"
                  required={true}
                  pattern="[0-9]*"
                  inputMode="numeric"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, '');
                  }}
                  event={(e) => setMatricula(e.target.value)}
                />
              </>
            ) : null}
            <Input
              width="30vh"
              placeholdercolor="black"
              color="black"
              type={"email"}
              placeholder={"Email"}
              required={"true"}
              event={(e) => {
                setEmail(e.target.value)
              }}
            />
            {['coordenador', 'professor'].includes(Perfil) ? null : (
              <Input
                width="30vh"
                color="black"
                placeholdercolor="black"
                type={"password"}
                placeholder={"Senha"}
                required={"true"}
                event={(e) => {
                  setSenha(e.target.value)
                }}
              />
            )}
          </Forms>
          <DivButtom>
            <Button
              id={"button"}
              width="20vh"
              event={(e) => {
                e.preventDefault()
                HandleManualSubmit(e)
              }}
              placeholder="Criar Usuario "
            />
          </DivButtom>
        </Card>
      </Contanier>
    )
  } else if (isActived == false) {
    return null
  }
}
