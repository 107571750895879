import React, { useEffect, useState, useCallback } from "react";
import {
  Background, Container, Close, Header, Form, DropDiv2, ImageContainer,
  InputFileManual, PreviewImage, RetunrIcon
} from "./style";
import Input from "../../Input";
import InputSelect from "../../Select";
import Icone from "../../../utils/Icone";
import Button from "../../Button";
import api from "../../../Services/api";
import UserDefault from "../../../assets/images/user.png";
import { UpdateImageApi } from "../../../Services/sendImage.";
import { format, parseISO } from 'date-fns';
import { Alert } from "../../../utils/Alert";
import Url_Image from "../../../assets/URL'S/Url_Image";

const EditAluno = ({ Show, setShow, editAluno, setEditAluno, cursos }) => {
  const [formData, setFormData] = useState({
    imageAluno: "",
    rm: "",
    nome: "",
    email: "",
    curso: null,
    convenio: "",
    dataNascimento: "",
    dataInicio: "",
    dataFim: "",
    FileManual: null
  });

  const [optionsCurso, setOptionsCurso] = useState([]);
  const [optionsConvenio, setOptionsConvenio] = useState([]);

  const updateFormData = (key, value) => {
    setFormData(prev => ({ ...prev, [key]: value }));
  };

  useEffect(() => {
    if (editAluno) {
      const initialCourse = { label: editAluno.nome_curso, value: editAluno.id_curso };
      const initialConvenio = { label: editAluno.convenio, value: editAluno.convenio };

      setOptionsCurso([initialCourse, ...cursos.filter(item => item.label !== editAluno.nome_curso)
        .map(item => ({ label: item.label, value: item.value }))]);
      setOptionsConvenio([initialConvenio]);

      setFormData({
        imageAluno: Url_Image(editAluno?.rm),
        rm: editAluno?.rm,
        nome: editAluno?.nome_aluno,
        email: editAluno?.email,
        curso: editAluno.id_curso,
        convenio: editAluno.convenio,
        dataNascimento: editAluno?.dt_nascimento ? format(parseISO(editAluno.dt_nascimento), 'yyyy-MM-dd') : '',
        dataInicio: editAluno?.dt_inicio ? format(parseISO(editAluno.dt_inicio), 'yyyy-MM-dd') : '',
        dataFim: editAluno?.dt_termino ? format(parseISO(editAluno.dt_termino), 'yyyy-MM-dd') : '',
        FileManual: null
      });
    }
  }, [editAluno, cursos]);

  useEffect(() => {
    if (formData.curso) {
      api.get(`/curso/listar-convenios?procurar=${formData.curso}`)
        .then(res => {
          setOptionsConvenio(res.data.map(value => ({
            label: value.convenio,
            value: value.convenio,
          })));
        })
        .catch(err => console.error(err));
    }
  }, [formData.curso]);

  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file?.type.includes("image") || file?.type.includes("png") || file?.type.includes("jpeg") || file?.type.includes("jpg")) {
      updateFormData('FileManual', file);
      updateFormData('imageAluno', URL.createObjectURL(file));
    } else {
      Alert('Alert2', 'Formato não permitido!');
    }
  }, []);

  const resetForm = useCallback(() => {
    setFormData({
      imageAluno: "",
      rm: "",
      nome: "",
      email: "",
      curso: "",
      convenio: "",
      dataNascimento: "",
      dataInicio: "",
      dataFim: "",
      FileManual: null
    });
  }, []);
  
  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (formData.FileManual) {
      UpdateImageApi(editAluno?.rm, formData.FileManual);
    }
    const data = {
      id_aluno: editAluno?.id,
      rm: editAluno?.rm,
      nome: formData.nome || editAluno?.nome_aluno,
      email: formData.email || editAluno?.email,
      dt_nascimento: formData.dataNascimento || format(parseISO(editAluno?.dt_nascimento), "yyyy-MM-dd"),
      dt_inicio: formData.dataInicio || format(parseISO(editAluno?.dt_inicio), "yyyy-MM-dd"),
      dt_termino: formData.dataFim || format(parseISO(editAluno?.dt_termino), "yyyy-MM-dd"),
      id_curso: formData.curso || editAluno?.id_curso,
    };
    const validationChecks = [
      { condition: !data.id_curso, message: 'Escolha um curso' },
      { condition: !data.nome, message: 'Insira o nome do aluno corretamente.' },
      { condition: !data.email, message: 'Insira o E-mail do aluno corretamente.' },
      { condition: !data.dt_nascimento, message: 'Insira a data de nascimento corretamente.' },
      { condition: !data.dt_inicio, message: 'Insira a data de inicio corretamente.' },
      { condition: !data.dt_termino, message: 'Insira a data de termino corretamente.' },
    ];
    for (const check of validationChecks) {
      if (check.condition) {
        Alert('Alert2', check.message);
        return;
      }
    }
    try {
      const response = await api.patch("/aluno/atualizar", data);
      Alert("Sucesso2", response.data.mensagem.split('.')[0] || "Dados atualizados com sucesso");
      setTimeout(() => { window.location.reload() }, 2050);
    } catch (err) {
      Alert('erro', err.response?.data?.erro.split('.')[0] || 'Falha ao atualizar os dados Aluno.');
      console.error(err);
    }
  }, [formData, editAluno]);

  if (!Show) {
    return null;
  }

  return (
    <Background Show={Show}>
      <Container>
        <Close>
          <div style={{ cursor: 'pointer' }}
            onClick={() => {
              setShow(false);
              setEditAluno("");
              resetForm();
            }}>
            <Icone name={"X"} color={" var(--azul)"} size={36} />
          </div>
        </Close>
        <Header>Edição</Header>
        <Form onSubmit={handleSubmit}>
          <DropDiv2>
            <ImageContainer>
              <InputFileManual
                placeholder="FOTO"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              <label htmlFor="">
                <PreviewImage src={formData.imageAluno} alt={formData.rm} />
              </label>
              {formData.FileManual && (
                <RetunrIcon
                  title="Voltar Imagem padrão"
                  onClick={() => {
                    updateFormData('FileManual', null);
                    updateFormData('imageAluno', editAluno?.foto ? formData.imageAluno : UserDefault);
                  }}
                />
              )}
            </ImageContainer>
          </DropDiv2>
          <Input
            placeholder="RM"
            placeholdercolor="var(--azul)"
            disabled={true}
            value={formData.rm}
          />
          <Input
            placeholder="Nome"
            placeholdercolor="var(--azul)"
            value={formData.nome}
            event={(e) => updateFormData('nome', e.target.value)}
          />
          <Input
            placeholder="E-mail"
            placeholdercolor="var(--azul)"
            value={formData.email}
            event={(e) => updateFormData('email', e.target.value)}
          />
          <Input
            type="date"
            color="var(--azul)"
            placeholder="Nascimento"
            placeholdercolor="var(--azul)"
            value={formData.dataNascimento}
            event={(e) => updateFormData('dataNascimento', e.target.value)}
          />
          <div style={{ display: 'flex', gap: '1rem' }}>
            <Input
              type="date"
              color="var(--azul)"
              placeholder="Inicio"
              placeholdercolor="var(--azul)"
              value={formData.dataInicio}
              event={(e) => updateFormData('dataInicio', e.target.value)}
            />
            <Input
              type="date"
              color="var(--azul)"
              placeholder="Fim"
              placeholdercolor="var(--azul)"
              value={formData.dataFim}
              event={(e) => updateFormData('dataFim', e.target.value)}
            />
          </div>
          <InputSelect
            Label={`Curso`}
            Style1={{ color: "var(--azul)" }}
            Style2={{ color: "var(--azul)" }}
            Options={optionsCurso}
            Default={optionsCurso.find(option => option.value === formData.curso)?.label || editAluno.id_curso}
            value={optionsCurso.find(option => option.value === formData.curso)?.label || editAluno.id_curso}
            OnChange={(e) => updateFormData('curso', e.target.value)}
          />
          <InputSelect
            Style1={{ color: "var(--azul)" }}
            Style2={{ color: "var(--azul)", marginBottom: '0.5rem' }}
            color="var(--azul)"
            placeholdercolor="var(--azul)"
            Label={"Convênio"}
            Options={optionsConvenio}
            Default={editAluno.convenio}
            value={optionsConvenio.find(option => option.value === formData.convenio)?.label || editAluno.convenio}
            OnChange={(e) => updateFormData('convenio', e.target.value)}
          />
          <Button
            width="100%"
            height="100%"
            placeholder="Atualizar"
            event={handleSubmit}
          />
        </Form>
      </Container>
    </Background>
  );
};

export default EditAluno;