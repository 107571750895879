import React from "react";
import { Card, Contanier, Forms, Header, Title } from "./style";
import Button from "../../../Button";

export default function EscolhaAcesso({ showEscolha, setShowEscolha, multipleAlunos, setMultipleAlunos, handleAlunoSelect }) {
  if (showEscolha) {
    if (!multipleAlunos || multipleAlunos.length === 0) {
      return (
        <Contanier>
          <Card>
            <Header>
              <Title>Nenhum aluno disponível</Title>
            </Header>
            <Button
              id={"button"}
              width="20vh"
              height="6vh"
              event={() => { setShowEscolha(false), setMultipleAlunos([]) }}
              placeholder="Fechar"
              marginBottom="40px"
            />
          </Card>
        </Contanier>
      );
    }
    return (
      <Contanier>
        <Card>
          <Header>
            <Title>Qual conta pretende entrar?</Title>
          </Header>
          <Forms>
            {/* {console.log('multipleAlunos', multipleAlunos)} */}
            {multipleAlunos.map((alunoItem, index) => (
              <div key={index} style={{ display: 'flex' }}>
                {alunoItem.mensagem?.includes('Informe uma senha para o aluno.') ? (
                  <div style={{ color: 'yellow' }}>
                    <Button
                      id={"button"}
                      event={() => handleAlunoSelect(alunoItem)}
                      placeholder={<p>{alunoItem.aluno.nome_aluno} - {alunoItem.aluno.nome_curso}</p>}
                      marginBottom="10px"
                    />
                  </div>
                ) : alunoItem.erro ? (
                  <div style={{ color: 'red' }}>
                    <Button
                      id={"button"}
                      colo={'red'}
                      event={() => handleAlunoSelect(alunoItem)}
                      placeholder={<p>{alunoItem.aluno.nome_aluno} - {alunoItem.aluno.nome_curso}</p>}
                      marginBottom="10px"
                    />
                  </div>
                ) : (
                  <Button
                    id={"button"}
                    event={() => handleAlunoSelect(alunoItem)}
                    placeholder={<p>{alunoItem.aluno.nome_aluno} - {alunoItem.aluno.nome_curso}</p>}
                    marginBottom="10px"
                  />
                )}
              </div>
            ))}
          </Forms>
          <Button
            id={"button"}
            width="20vh"
            height="6vh"
            margintop="6vh"
            event={() => {
              localStorage.clear();
              setShowEscolha(false);
              setMultipleAlunos([]);
            }}
            placeholder="Fechar"
            marginBottom="40px"
          />
        </Card>
      </Contanier>
    );
  }
  return null;
}
