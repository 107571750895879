import { FaFileImport } from "react-icons/fa"
import styled, { css, keyframes } from "styled-components"
import { HiTrash } from "react-icons/hi"

const moveInL = keyframes`
  from { 
    transform: translate(-100%, 0);
 }
  to { 
    transform: translate( 0%, 0);
 }
`

const moveInR = keyframes`
    from {
         transform: translate(0%, 0);
    }
    to {
         transform: translate(-100%, 0);
    }
`

export const Container = styled.div`
  margin-top: 2vh;
  display: flex;
  position: relative;
  flex-direction: row;
  height: 76vh;
  width: 100%;
  gap: 0.5%;
  overflow: hidden;
`

export const FirstCard = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  z-index: 999;
  background-color: var(--azul);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  border-radius: 0px 30px 30px 0px;
  height: 90.5vh;
  width: 100vw;
  overflow: hidden;
  animation: ${(props) =>
    props.Estado == true
      ? css`
          ${moveInL} 0.5s;
        `
      : css`
          ${moveInR} 0.5s
        `};
`

export const SecondCard = styled.div`
  margin-top: 0.5vh;
  background-color: var(--background);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 49.75%;
  max-height: 82vh;
  border-radius: 30px 0px 0px 30px;
  transition: transform 0.5s;

  &:hover {
    transform: scale(1.01);
    transition: ease-in-out 0.5s;
  }
`

export const InputFileManual = styled.input`
  position: relative;
  opacity: 0%;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: 1px solid black;

  &:hover {
    cursor: pointer;
  }
`

export const InputFile = styled.input`
  position: absolute;
  opacity: 0%;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid black;
  width: 100%;
  height: 100%;
  border-radius: 70px;

  &:hover {
    cursor: pointer;
  }
`

export const Spacediv = styled.div`
  height: 89vh;
`

export const ImageContainer = styled.div`
  position: relative;
  border-radius: 20px;
  width: 100%;
`

export const Image = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;

  &[alt]:after {
    content: "Não suportado!";
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    background-color: var(--secondary-bg-color);
    color: var(--primary-font-color);
  }
`

export const RemoveIcon = styled(HiTrash)`
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;

  &:hover {
    transform: scale(1.2);
    color: var(--main-color);
  }
`

export const DropDiv = styled.div`
  display: flex;
  margin-top: 8%;
  margin-right: auto;
  margin-left: auto;
  width: 30%;
  height: 50%;
  border: 3px dashed grey;
  border-radius: 70px;
  transition: transform 1s;

  &:hover {
    transform: scale(1.05);
    transition: transform 1s;
    cursor: pointer;
    border: 3px dashed var(--azul);
    animation: rotate 2s infinite linear;
  }
`

export const DropDiv2 = styled.div`
  display: flex;
  margin-right: auto;
  margin-left: auto;
  width: 5%;
  height: 12%;
  border: 3px dashed grey;
  border-radius: 20px;
  transition: transform 1s;
  transition: color 1s;

  &:hover {
    transform: scale(1.05);
    transition: transform 1s;
    cursor: pointer;
    border: 3px dashed white;
    animation: rotate 2s infinite linear;
  }
`

export const Field = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10em;
  border-radius: 25px;
  padding: 0.6em;
  outline: none;
  color: var(--background);
  /* border: 1px solid black; */
  width: 60%;
  background-color: transparent;

  div{
    .color{
      ${({ $color }) => $color == '' ? `
       border: 0;
       display: none;
       ` : `
       display: flex;
       border: 1px solid ${$color == 'black' ? 'white' : 'black'};
       background-color: ${$color}
       `
      };
      width: 20px;
      height: 20px;
      border-radius: 100px;
      margin-bottom: 5px;
    }
  }

`

export const Heading = styled.div`
  display: block;
  white-space: nowrap;
  margin-bottom: 1em;
  margin-top: 5vh;
  color: var(--background);
  font-size: 4em;
  background-color: transparent;
  font-family: Dancing Script;
`

export const Heading2 = styled.div`
  display: block;
  /* margin-left: auto; */
  /* margin-right: 5vh; */
  white-space: nowrap;
  text-align: center;
  /* margin-bottom: 1.5em; */
  margin-top: 5vh;
  color: var(--azul);
  /* width: 35%; */
  font-size: 4em;
  background-color: transparent;
  border-radius: 20px;
  font-family: Dancing Script;
  /* border: 1px solid black; */
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
`

export const Icon = styled(FaFileImport)`
  position: relative;
  place-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  z-index: 9;
  height: 20%;
  width: 20%;
  color: grey;
  transition: color 0.5s;
  ${DropDiv}:hover & {
    color: var(--azul);
  }
`

export const Button1 = styled.button`
  font-size: larger;
  border: none;
  background-color: var(--azul);
  margin-left: 40%;
  margin-right: 8vh;
  color: var(--background);
  border-radius: 15px;
  width: 150px;
  height: 50px;
  transition: transform 1s;
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);

  &::before {
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  }

  &:hover {
    transform: scale(1.05);
    border: 1px solid var(--azul);
    cursor: pointer;
    color: var(--azul);
    background-color: var(--background);
  }
`

export const Button2 = styled.button`
  font-size: larger;
  border: none;
  background-color: var(--azul);
  color: var(--background);
  border-radius: 15px;
  width: 150px;
  height: 50px;
  transition: transform 0.5s;
  box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);

  &::before {
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
    border: 1px solid var(--azul);
    color: var(--azul);
    background-color: var(--background);
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  // border: 1px solid black;
  max-width: 400px;
  // padding: 10px 0;
`

export const ButtonsDiv = styled.div`
  // border: 1px solid red;
`

export const Imagecsv = styled.div``

export const Arroba = styled.button`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-top: 1%;
  align-self: end;
  margin-right: 1%;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
`
