import React from "react";
import naoAutorizado from "../../assets/images/naoautorizado.png"
import { Container, Img } from "./style";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button";

export default function NaoAutorizado() {
    const nav = useNavigate();
    localStorage.clear();

    return (
        <Container>
            <Img src={naoAutorizado} alt="Não autorizado" />
            {/* <Button event={() => nav('/')} placeholder={'SAIR'} /> */}
        </Container>
    );
};