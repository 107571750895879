import React, { useState } from 'react'
import Button from '../../Components/Button'
import jwt_decode from "jwt-decode"
import { useNavigate } from 'react-router-dom'
import { Container, LeftDiv, RightDiv, Titulo, Card, TitleHolder } from './style'
import User from "../../assets/images/user.png"
import { FaUser } from 'react-icons/fa'
import RecuperarSenha from '../../Components/modals/RecuperarSenha'

export default function CoordenadorHome() {
  const [isPasswordResetActive, setIsPasswordResetActive] = useState(false);
  const Navigate = useNavigate()
  const token = localStorage.getItem("token")
  const decoded = jwt_decode(token)

  return (
    <Container>
      <RecuperarSenha isActived={isPasswordResetActive} setIsActived={setIsPasswordResetActive} perfil={'coodenador'} />
      <LeftDiv>
        <Card>
          <TitleHolder>
            <Titulo>Coordenador(a)</Titulo>
          </TitleHolder>
          <div style={{ width: "fit-content", height:'55%' }}>
            {/* <img src={User} alt="perfil" className="pefil"  */}
            <img  src={User} alt="perfil" className="pefil" />
            {/* <FaUser style={{ fontSize: "1000%", color: '#fff' }} /> */}
          </div>
          <TitleHolder>
            <Titulo>{decoded.nome}</Titulo>
          </TitleHolder>
        </Card>
      </LeftDiv>
      <RightDiv>
        <Button
          width="250px"
          height="55px"
          colo={'#0048CC'}
          backgroundcolor={'#fff'}
          border={'2px solid #0048CC'}
          placeholder="Progressão Parcial"
          event={() => Navigate("/progressao-parcial-coordenador")}
        />
        <Button
          width="250px"
          height="55px"
          colo={'#0048CC'}
          backgroundcolor={'#fff'}
          border={'2px solid #0048CC'}
          placeholder="Atualizar Senha"
          event={() => setIsPasswordResetActive(true)}
        />
        <Button
          width="250px"
          height="55px"
          colo={'#0048CC'}
          backgroundcolor={'#fff'}
          border={'2px solid #0048CC'}
          placeholder="Sair"
          event={() => Navigate("/")}
        />
      </RightDiv>
    </Container>
  )

}