import styled from "styled-components"

export const Contanier = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(3px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
`
export const Card = styled.div`
  width: 30vw;
  height: fit-content;
  border-radius: 10px;
  background-color: white;
  -webkit-box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
  padding: 0 0 2rem 0;

  @media (max-width: 800px) {
    width: 80%;
    padding: 1rem 1rem 2rem 1rem;
    gap: 20px;
    font-size: 1.6rem;
  }
`
export const Title = styled.h1`
  width: 100%;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: var(--azul);

  @media (max-width: 800px) {
    font-size: 1.8rem;
  }
`
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`

export const Forms = styled.div`
  width: 85%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: left;

  @media (max-width: 800px) {
    font-size: 1rem;
    height: fit-content;
  }
`

export const Buttons = styled.div`
  width: 10%;
  padding-right: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-7px);
  }
`

export const ButtonNew = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`
export const ButtonSeach = styled.button`
  background: #3a3f86;
  border: none;
  align-items: center;
  justify-content: center;
  color: white;
  width: 8rem;
  height: 2rem;
  border-radius: 10%;
`

export const paragrafo = styled.p`
  font-size: 1rem;
`

export const Gruop = styled.div`
  width: 85%;
  height: 70%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;

  @media (max-width: 800px) {
    gap: 2rem;
  }
`
