import React, { useEffect, useState } from "react";
import { Background, Close, Container, Content, DivContent, DivMain, Header, PreviewImage, Title } from "./style";
import Button from "../../Button";
import api from "../../../Services/api";
import Url_Image from "../../../assets/URL'S/Url_Image";
import { format, parseISO } from "date-fns";
import Loading from "../../../utils/Loading/Loading";

const ViewAluno = ({ Show, setShow, id_Aluno, setId_Aluno, id_Usuario, setId_Usuario, rm, setRm }) => {
  const [imageAluno, setImageAluno] = useState("");
  const [user, setUser] = useState(null);
  const [erro, setErro] = useState('');
  const [escolha, setEscolha] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);

  useEffect(() => {
    if (Show) {
      if (escolha) {
        fetchUserData(escolha);
      } else {
        fetchUserData();
      }
    }
  }, [Show, escolha]);

  const fetchUserData = async (escolha) => {
    setLoading(true);
    try {
      let response;
      if (escolha == null) {
        if (id_Aluno) {
          response = await api.get(`/aluno/buscar?id_aluno=${id_Aluno}&rm=${rm}`);
        } else if (id_Usuario) {
          response = await api.get(`/usuario/buscar?id_usuario=${id_Usuario}`);
        } else {
          setErro('Informações insuficientes.');
          return;
        }
      } else {
        if (escolha === 'aluno') {
          response = await api.get(`/aluno/buscar?id_aluno=${id_Aluno}&rm=${rm}`);
        } else if (escolha === 'usuario') {
          response = await api.get(`/usuario/buscar?id_usuario=${id_Usuario}`);
        } else {
          setErro('Informações insuficientes.');
          return;
        }
      }

      const data = response.data;
      setUser(data);

      if (escolha === 'aluno' || id_Aluno) {
        setLoadingImg(true);
        setImageAluno(Url_Image(data.rm || rm));
      }
    } catch (err) {
      console.error("Erro ao buscar dados:", err);
      setErro("Erro ao buscar os dados.");
    } finally {
      setLoading(false);
      setLoadingImg(false);
    }
  };

  const closeModal = () => {
    setShow(false);
    setUser(null);
    setImageAluno("");
    setErro('');
    setId_Aluno(null);
    setId_Usuario(null);
    setRm(null);
    setEscolha(null);
  };

  if (!Show) return null;

  if (erro) {
    return (
      <Background Show={Show}>
        <Container>
          <Title>{erro}</Title>
          <DivMain>
            <Button
              type="button"
              width="50%"
              height="100%"
              placeholder="Fechar"
              event={closeModal}
            />
          </DivMain>
        </Container>
      </Background>
    );
  }

  return (
    <Background Show={Show}>
      <Container>
        {rm && id_Usuario && escolha == null ? (
          <Title>Escolha</Title>
        ) : rm && id_Usuario && escolha != null ? (
          <Title>{escolha == 'usuario' ? 'Informações do Usuário' : 'Informações do Aluno'}</Title>
        ) : (
          <Title>{id_Usuario ? 'Informações do Usuário' : 'Informações do Aluno'}</Title>
        )}
        <DivMain>
          {(rm && id_Usuario && escolha != null) && (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10%', width: "100%" }}>
              <Button
                type="button"
                width="10rem"
                height="100%"
                placeholder="Ver Usuário"
                event={() => setEscolha('usuario')}
              />
              <Button
                type="button"
                width="10rem"
                height="100%"
                placeholder="Ver Aluno"
                event={() => setEscolha('aluno')}
              />
            </div>
          )}
          {(rm && id_Usuario && escolha == null) ? (
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10%', width: "100%" }}>
              <Button
                type="button"
                width="10rem"
                height="100%"
                placeholder="Ver Usuário"
                event={() => setEscolha('usuario')}
              />
              <Button
                type="button"
                width="10rem"
                height="100%"
                placeholder="Ver Aluno"
                event={() => setEscolha('aluno')}
              />
            </div>
          ) : (
            <>
              {loading ? (
                <Loading />
              ) : (
                <>
                  {(escolha === 'aluno' || id_Aluno) ? (
                    <>
                      {loadingImg ? <Loading /> : <PreviewImage src={imageAluno} alt={user?.rm} />}
                      <DivContent>
                        <Header>RM:</Header><Content>{user?.rm}</Content>
                        <Header>NOME:</Header><Content>{user?.nome_aluno}</Content>
                        <Header>DATA NASCIMENTO:</Header><Content>{user?.dt_nascimento ? format(parseISO(user?.dt_nascimento), 'dd/MM/yyyy') : 'Sem data de nascimento'}</Content>
                        <Header>DATA INÍCIO:</Header><Content>{user?.dt_inicio ? format(parseISO(user?.dt_inicio), 'dd/MM/yyyy') : 'Sem data de início'}</Content>
                        <Header>DATA TÉRMINO:</Header><Content>{user?.dt_termino ? format(parseISO(user?.dt_termino), 'dd/MM/yyyy') : 'Sem data de término'}</Content>
                        <Header>CURSO:</Header><Content>{user?.nome_curso}</Content>
                        <Header>CONVÊNIO:</Header><Content>{user?.convenio}</Content>
                      </DivContent>
                    </>
                  ) : (
                    <DivContent>
                      <Header>NOME:</Header><Content>{user?.nome}</Content>
                      <Header>E-MAIL:</Header><Content>{user?.email}</Content>
                      <Header>PERFIL:</Header><Content>{user?.perfil}</Content>
                      {user?.perfil == 'secretaria' ? null : <><Header>MATRÍCULA:</Header><Content>{user?.matricula || 'Sem matrícula'}</Content></> }
                    </DivContent>
                  )}
                </>
              )}
            </>
          )}
          <Button
            type="button"
            width="50%"
            height="100%"
            placeholder="Fechar"
            event={closeModal}
          />
        </DivMain>
      </Container>
    </Background>
  );
};

export default ViewAluno;
