import jwt_decode from "jwt-decode"

export const Authenticator = () => {
  const token = localStorage.getItem("token")

  try {
    const decoded = jwt_decode(token)
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token")
      return false
    }
    if (decoded.perfil === "aluno") return true
    else return false
  } catch (error) {
    localStorage.removeItem("token")
    return false
  }
}

export const AuthenticatorAdmin = () => {
  const token = localStorage.getItem("token")
  try {
    const decoded = jwt_decode(token)
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token")
      return false
    }
    if (decoded.perfil === "secretaria") return true
    else return false
  } catch (error) {
    localStorage.removeItem("token")
    return false
  }
}

export const AuthenticatorProfessor = () => {
  const token = localStorage.getItem("token")

  try {
    const decoded = jwt_decode(token)
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token")
      return false
    }
    if (decoded.perfil === "professor" || decoded.perfil === "coordenador") return true
    else return false
  } catch (error) {
    localStorage.removeItem("token")
    return false
  }
}

export const AuthenticatorCoordenador = () => {
  const token = localStorage.getItem("token")

  try {
    const decoded = jwt_decode(token)
    if (decoded.exp < Math.floor(Date.now() / 1000)) {
      localStorage.removeItem("token")
      return false
    }
    if (decoded.perfil === "coordenador" || decoded.perfil === "secretaria") return true
    else return false
  } catch (error) {
    localStorage.removeItem("token")
    return false
  }
}
