import Url_DocS from "../json/texts.json"

export function Url_Docs(nameFile) {
    return (
        window.location.href.includes("localhost") ? `${Url_DocS.STATIC_URL_DOCS_DEV}${nameFile ? '?fileName='+nameFile : ''}`:
        window.location.href.includes("testecart.fiecdev") ? `${Url_DocS.STATIC_URL_DOCS_TESTE}${nameFile ? '?fileName='+nameFile : ''}` :
          window.location.href.includes("alunos.fiecdev") ? `${Url_DocS.STATIC_URL_DOCS_PROD}${nameFile ? '?fileName='+nameFile : ''}` : null
    )
}

// export function Url_Docs_Info(ID_PFD) {
//     return (
//         window.location.href.includes("localhost") ? `${Url_Docs.STATIC_URL_DOCS_DEV}${ID_PFD}`:
//         window.location.href.includes("testecart.fiecdev") ? `${Url_Docs.STATIC_URL_DOCS_iNFO_TESTE}${ID_PFD}` :
//           window.location.href.includes("alunos.fiecdev") ? `${Url_Docs.STATIC_URL_DOCS_iNFO_PROD}${ID_PFD}` : null
//     )
// }

