import React, { useEffect, useState, useMemo } from "react";
import "./style.css";
import { Container, ContainerTable, Menus, Table, Theader, Trbody, Td, Th, Title, Buttons, Grup, Tbody, VerMais, TdNome, VerMaisIcon } from "./style";
import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import Pesquisar from "../../Components/Pesquisar";
import api from "../../Services/api";
import InputSelect from "../../Components/Select";
import Input from "../../Components/Input";
import ViewAluno from "../../Components/modals/ViewAluno";

export default function VerCarterinhas() {
  const navigate = useNavigate();
  const [Show, setShow] = useState(false);
  const [logs, setLogs] = useState([]);
  const [searchNomeUsuario, setSearchNomeUsuario] = useState("");
  const [searchNomeAluno, setSearchNomeAluno] = useState("");
  const [searchAcao, setSearchAcao] = useState("");
  const [searchDataHora, setSearchDataHora] = useState("");
  const [searchMensagem, setSearchMensagem] = useState("");
  const [mostrarTodos, setMostrarTodos] = useState(250);
  const [optionsAcao, setOptionsAcao] = useState([]);
  const [id_Aluno, setId_Aluno] = useState('');
  const [id_Usuario, setId_Usuario] = useState('');
  const [rm, setRm] = useState('');
  const limite = 0.20;

  useEffect(() => {
    getallLogs();
  }, []);

  async function getallLogs() {
    try {
      const response = await api.get("/log/listar");
      const data = response.data;
      setLogs(data);
      setMostrarTodos(Math.floor(data.length * limite));

      const actionSet = new Set(data.map(value => {
        if (value.acao) {
          return value.acao.split(' do ip')[0].split(" ip")[0].split('undefined')[0];
        }
        return null;
      }).filter(Boolean));

      const resultsAcao = Array.from(actionSet).map((acao) => ({
        label: acao,
        value: acao,
      }));
      setOptionsAcao([{ label: "Escolha uma ação", value: "" }, ...resultsAcao]);
    } catch (err) {
      console.error(err);
    }
  }

  const normalizeString = (str) => {
    if (str === undefined || str === null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredLogs = useMemo(() => {
    return logs.filter((log) => {
      const searchNomeUsuarioNormalized = normalizeString(searchNomeUsuario);
      const searchNomeAlunoNormalized = normalizeString(searchNomeAluno);
      const searchAcaoNormalized = normalizeString(searchAcao);
      const searchDataHoraNormalized = normalizeString(searchDataHora);
      const searchMensagemNormalized = normalizeString(searchMensagem);

      const logNomeUsuario = normalizeString(log.nome_secretario || "");
      const logNomeAluno = normalizeString(log.nome_aluno || "");
      const logAcao = normalizeString(log.acao || "");
      const logDataHora = normalizeString(log.data_hora ? format(new Date(log.data_hora), "dd/MM/yyyy HH:mm:ss") : "");
      const logMensagem = normalizeString(log.mensagem || "");

      return (
        logNomeUsuario.includes(searchNomeUsuarioNormalized) &&
        logNomeAluno.includes(searchNomeAlunoNormalized) &&
        logAcao.includes(searchAcaoNormalized) &&
        logDataHora.includes(searchDataHoraNormalized) &&
        logMensagem.includes(searchMensagemNormalized)
      );
    }).slice(0, mostrarTodos);
  }, [logs, searchNomeUsuario, searchNomeAluno, searchAcao, searchDataHora, searchMensagem, mostrarTodos]);

  const handleVerMais = (log) => {
    setShow(true);
    if (log.nome_aluno != "0") {
      setId_Aluno(log.id_aluno);
    } else if (log.mensagem?.includes('RM:') && !log.mensagem?.includes('RM:undefined')) {
      const rmValue = log.mensagem.split('RM:')[1].trim();
      setRm(rmValue);
      setId_Usuario(log.id_usuario);
    } else if (log.nome_secretario != "0") {
      setId_Usuario(log.id_usuario);
    }
  };

  return (
    <Container>
      <Menus>
        <Title>Controle de Acessos</Title>
        <Buttons>
          <Button
            event={() => navigate("/secretaria")}
            width="8rem"
            height="3.5rem"
            margintop="1.5rem"
            placeholder="Voltar"
            backgroundcolor="#1a2e35"
            borderRadius="30px 30px 0px 0px"
          />
          {(!searchNomeUsuario && !searchNomeAluno && !searchAcao && !searchDataHora && !searchMensagem) ? (
            <Button
              event={() => setMostrarTodos(prev => prev < logs.length ? logs.length : Math.floor(logs.length * limite))}
              width="12rem"
              height="3.5rem"
              margintop="1.5rem"
              backgroundcolor="#1a2e35"
              placeholder={mostrarTodos < logs.length ? "Mostrar Todos" : "Reduzir Logs"}
              borderRadius="30px 30px 0px 0px"
            />
          ) : null}
        </Buttons>
        <Title>{filteredLogs.length}</Title>
      </Menus>
      <ContainerTable>
        <Table>
          <Theader>
            <tr>
              <Th>
                <Grup>
                  <Pesquisar
                    color=""
                    placeholder="Nome do Usuário"
                    name="nome_usuario"
                    event={(e) => setSearchNomeUsuario(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    placeholder="Nome do Aluno"
                    name="nome_aluno"
                    event={(e) => setSearchNomeAluno(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <InputSelect
                    Style1={{ color: '#f8f8f8' }}
                    Style2={{ color: '#f8f8f8', backgroundColor: '#1a2e35' }}
                    Style3={{ color: '#f8f8f8', backgroundColor: '#1a2e35' }}
                    Label={"Açao"}
                    Options={optionsAcao}
                    value={searchAcao}
                    Default={searchAcao}
                    OnChange={(e) => setSearchAcao(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    placeholder="Data e Hora"
                    name="data"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    maxLength={19} // Limita a entrada ao tamanho da data/hora
                    onInput={(e) => {
                      let value = e.target.value.replace(/\D/g, ''); // Remove qualquer caractere que não seja número
                      if (value.length > 2) { value = value.slice(0, 2) + '/' + value.slice(2); }
                      if (value.length > 5) { value = value.slice(0, 5) + '/' + value.slice(5); }
                      if (value.length > 10) { value = value.slice(0, 10) + ' ' + value.slice(10); }
                      if (value.length > 13) { value = value.slice(0, 13) + ':' + value.slice(13); }
                      if (value.length > 16) { value = value.slice(0, 16) + ':' + value.slice(16); }
                      e.target.value = value; // Atualiza o campo de input com o formato
                    }}
                    event={(e) => setSearchDataHora(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th>
                <Grup>
                  <Pesquisar
                    placeholder="Mensagem"
                    name="mensagem"
                    event={(e) => setSearchMensagem(e.target.value)}
                  />
                </Grup>
              </Th>
              <Th></Th>
            </tr>
          </Theader>
          <Tbody>
            {filteredLogs.map((log) => (
              <Trbody key={log.id}>
                <Td>{log.nome_secretario === "0" ? " " : <TdNome>{log.nome_secretario}</TdNome>}</Td>
                <Td>{log.nome_aluno === "0" ? "" : <TdNome>{log.nome_aluno}</TdNome>}</Td>
                <Td>{log.acao}</Td>
                <Td>{format(new Date(log.data_hora), "dd/MM/yyyy HH:mm:ss")}</Td>
                <Td>{log.mensagem}</Td>
                <Td>
                  {(log.mensagem?.includes('RM:') && !log.mensagem?.includes('RM:undefined')) ||
                    log.nome_secretario != "0" ||
                    log.nome_aluno != "0" ? (
                    <TdNome>
                      <VerMais onClick={() => handleVerMais(log)}>
                        <VerMaisIcon title={
                          (log.nome_secretario != "0" && log.mensagem?.includes('RM:') && !log.mensagem?.includes('RM:undefined')) ?
                            'Ver Informações' : log.nome_aluno != "0" ? 'Ver Informações do Aluno' : 'Ver Informações do Usuario'} />
                      </VerMais>
                    </TdNome>
                  ) : null}
                </Td>
              </Trbody>
            ))}
          </Tbody>
        </Table>
      </ContainerTable>
      <ViewAluno
        Show={Show}
        setShow={setShow}
        id_Usuario={id_Usuario}
        setId_Usuario={setId_Usuario}
        id_Aluno={id_Aluno}
        setId_Aluno={setId_Aluno}
        rm={rm}
        setRm={setRm}
      />
    </Container>
  );
}