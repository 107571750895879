import Url_Pdfs from "../json/texts.json"

export function Url_Pdf(ID_PFD) {
    return (
        window.location.href.includes("localhost") ? `${Url_Pdfs.STATIC_URL_PDF_DEV}${ID_PFD}`:
        window.location.href.includes("testecart.fiecdev") ? `${Url_Pdfs.STATIC_URL_PDF_TESTE}${ID_PFD}` :
          window.location.href.includes("alunos.fiecdev") ? `${Url_Pdfs.STATIC_URL_PDF_PROD}${ID_PFD}` : null
    )
}

export function Url_Pdf_Info(ID_PFD) {
    return (
        window.location.href.includes("localhost") ? `${Url_Pdfs.STATIC_URL_PDF_INFO_DEV}${ID_PFD}`:
        window.location.href.includes("testecart.fiecdev") ? `${Url_Pdfs.STATIC_URL_PDF_iNFO_TESTE}${ID_PFD}` :
          window.location.href.includes("alunos.fiecdev") ? `${Url_Pdfs.STATIC_URL_PDF_iNFO_PROD}${ID_PFD}` : null
    )
}

