import React, { useEffect, useState, useCallback } from 'react';
import {
  Background, DivSave, Title, Cardo, ContainerTable, Table, Theader, Th, Tbody, Trbody, Td, DivMain, FormDiv, PdfViewer,
  DragAndDropArea, HiddenFileInput, FileInfo, FileDetails, Instructions, DragAndDropArea2, ButtonSearch, DivTitle,
  DivText,
  InputTextarea,
  DivTextarea
} from './style';
import { Grup } from '../CardEditar/style';
import Button from '../../Button';
import InputSelect from '../../Select';
import api from '../../../Services/api';
import Swal from 'sweetalert2';
import { FaSearch } from 'react-icons/fa';
import CheckBox from '../../Checkbox';
import Input from '../../Input';
import { Alert } from '../../../utils/Alert';
import { addDays, format, isValid, parseISO } from 'date-fns';
import Pesquisar from '../../Pesquisar';

const CardAdicionar = ({ Show, setShow }) => {
  if (!Show) { return null }
  const [cursos, setCursos] = useState([]);
  const [selectedCursoNome, setSelectedCursoNome] = useState("");
  const [selectedCursoId, setSelectedCursoId] = useState("");
  const [turmas, setTurmas] = useState([]);
  const [selectedTurmaNome, setSelectedTurmaNome] = useState("");
  const [selectedTurmaId, setSelectedTurmaId] = useState("");
  const [disciplinas, setDisciplinas] = useState([]);
  const [selectedDisciplinaNome, setSelectedDisciplinaNome] = useState("");
  const [selectedDisciplinaId, setSelectedDisciplinaId] = useState("");
  const [alunos, setAlunos] = useState([])
  const [selecionarAluno, setSelecionarAluno] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [steps, setSteps] = useState(1);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [orientacoes, setOrientacoes] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [searchRm, setSearchRm] = useState("");
  const [searchNome, setSearchNome] = useState("");

  const tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");
  const afterTomorrow = format(addDays(new Date(), 2), "yyyy-MM-dd");

  useEffect(() => {
    if (!Show) { return null }
    setDataInicio(tomorrow);
    setDataFim(afterTomorrow)
    async function listarCursos() {
      try {
        const res = await api.get("/professor/listar-cursos-rh");
        const data = res.data;
        const resultsCurso = data.map(value => ({
          label: value.nome,
          value: value.id + ',' + value.nome,
        }));
        setCursos([{ label: "-", value: 0 }, ...resultsCurso]);
      } catch (err) {
        console.log("Erro: ", err);
        Swal.fire({
          title: "Erro",
          text: err.response?.data?.erro || "Erro interno de servidor",
          icon: "error",
          iconColor: "#ff0000",
          confirmButtonColor: "var(--azul)",
          confirmButtonText: "Confirmar",
        }).then((result) => { if (result.isConfirmed) setShow(false) });
      }
    }
    listarCursos();
  }, []);

  useEffect(() => {
    if (!Show) { return null }
    listarTurmas(selectedCursoId);
  }, [selectedCursoId]);

  useEffect(() => {
    if (!Show) { return null }
    getDisciplina(selectedTurmaId);
  }, [selectedTurmaId]);

  useEffect(() => {
    if (!Show) { return null }
    setAlunos([])
  }, [selectedDisciplinaId]);

  useEffect(() => {
    if (!Show) { return null }
    if (alunos.length) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [alunos, selecionarAluno]);

  async function listarTurmas(CursoId) {
    setAlunos([]);
    setTurmas([]);
    setDisciplinas([]);
    setSelecionarAluno([])
    setSelectedDisciplinaId(0);
    if (CursoId == 0 || CursoId == '') {
      setSelectedTurmaId(0);
    } else if (CursoId != 0 || CursoId != undefined || CursoId != '') {
      try {
        const res = await api.post(`/professor/listar-turmas-rh?id=${CursoId}`);
        const data = res.data;
        const resultstTurmas = data.map(value => ({
          label: value.nome.split('-').slice(1).join(' - ').trim(),
          value: value.id + ',' + value.nome,
        }));
        setTurmas([{ label: "-", value: 0 }, ...resultstTurmas]);
      } catch (err) {
        if (err.response?.data?.erro === 'Não possui Turmas.') {
          setDisciplinas([]);
          setSelectedDisciplinaId(0);
          setTurmas([{ label: "Esse curso não possui turmas associadas", value: 0 }]);
        }
        console.log("Erro: ", err);
        // console.clear();
      }
    }
  }

  async function getDisciplina(turmaId) {
    setAlunos([])
    setDisciplinas([]);
    setSelecionarAluno([])
    setSelectedDisciplinaId(0);
    if (turmaId == 0 || turmaId == '') {
      setSelectedDisciplinaId(0);
    } else if (turmaId != 0 || turmaId != undefined || turmaId != '') {
      try {
        const res = await api.get(`/professor/listar-disciplina-rh?idTurma=${turmaId}`);
        const data = res.data;
        const resultsDisciplina = data.map(value => ({
          label: value.disciplina,
          value: value.id_disciplina + ',' + value.disciplina,
        }));
        setDisciplinas([{ label: "-", value: 0 }, ...resultsDisciplina]);
      } catch (err) {
        if (err.response?.data?.erro === 'Não possui disciplinas.') {
          setDisciplinas([{ label: "Essa turma não possui disciplinas associadas", value: 0 }]);
        }
        console.log("Erro: ", err);
        // console.clear();
      }
    }
  }

  function ProcurarAlunos(CursoId) {
    setSelecionarAluno([])
    if (selectedDisciplinaId == 0 || selectedDisciplinaId == '') {
      setAlunos([])
      setSelecionarAluno([])
    } else if (CursoId != 0 || CursoId != undefined || CursoId != '') {
      api.get(`/professor/listar-alunos?id=${CursoId}`)
        .then(async (response) => {
          if (response.data.length != 0) {
            const data = await response.data
            setAlunos(data)
            return
          }
          setAlunos([])
          setSelecionarAluno([])
        })
        .catch((err) => {
          setAlunos([])
          setSelecionarAluno([])
          console.error(err)
        })
    }
  }

  function formatFileSize(size) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return `${size.toFixed(2)} ${units[index]}`;
  };

  function Selecionar(id, rm) {
    setSelecionarAluno((prevState) =>
      prevState.some((aluno) => aluno.id === id && aluno.rm === rm)
        ? prevState.filter((aluno) => aluno.id !== id || aluno.rm !== rm)
        : [...prevState, { id, rm }]
    );
  };

  const date = new Date();
  const dataStr = date.toLocaleString('pt-BR').split(' ')[0];
  const [dia, mes, ano] = dataStr.split('/');
  const hoje = (`${ano}-${mes}-${dia}`).replace(',', '');

  async function handleUploadClick() {
    if (file && selectedCursoId && selectedTurmaId && selectedDisciplinaId && dataInicio && dataFim) {
      const agora = new Date();
      const [dataStr, horaStr] = agora.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' }).split(' ');
      const [dia, mes, ano] = dataStr.split('/');
      const [horas, minutos, segundos] = horaStr.split(':');
      const data = {
        curso: `${selectedCursoId}, ${selectedCursoNome}`,
        turma: `${selectedTurmaId}, ${selectedTurmaNome}`,
        disciplina: `${selectedDisciplinaId}, ${selectedDisciplinaNome}`,
        data_inicio: dataInicio,
        data_fim: dataFim,
        id_pdf: (`${ano}${mes}${dia}${horas}${minutos}${segundos}`).replace(',', ''),
        orientacoes,
      };
      if (data.data_inicio === data.data_fim) {
        Swal.fire({
          title: "Erro",
          html: `<p>Insira uma <strong>Data de Início</strong> diferente da <strong>Data de Término</strong></p>`,
          icon: "error",
          iconColor: "red",
          confirmButtonColor: "var(--azul)",
          confirmButtonText: "Confirmar",
        });
      } else if (data.data_inicio > data.data_fim) {
        Swal.fire({
          title: "Erro",
          html: `<p>Insira uma <strong>Data de Término</strong> maior que <strong>Data de Início</strong></p>`,
          icon: "error",
          iconColor: "red",
          confirmButtonColor: "var(--azul)",
          confirmButtonText: "Confirmar",
        });
      } else if (data.data_inicio < hoje) {
        Swal.fire({
          title: "Erro",
          html: `<p>Insira uma <strong>Data de Início</strong> maior que <strong>${(`${dia - 1}/${mes}/${ano}`).replace(',', '')}</strong></p>`,
          icon: "error",
          iconColor: "red",
          confirmButtonColor: "var(--azul)",
          confirmButtonText: "Confirmar",
        });
      } else {
        try {
          setUploading(true);
          const formData = new FormData();
          Object.keys(data).forEach((key) => {
            formData.append(key, data[key]);
          });
          formData.append("file", file);
          const response = await api.post("/professor/cadastrar-progressao", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          const id_progressao = response.data.id;
          try {
            await Promise.all(
              selecionarAluno.map(async (aluno) => {
                try {
                  return await api.post("/professor/inserir-alunos-progressao", {
                    rm: aluno.rm,
                    id_progressao: id_progressao,
                    id_aluno: aluno.id,
                  });
                } catch (err) {
                  console.error(err);
                  Alert("Erro", "Ocorreu um erro ao inserir o aluno na progressão");
                }
              })
            );
            await Alert("Sucesso2", "Progressão criada com sucesso!")
          } catch (err) {
            console.error(err);
            Alert("Erro", "Ocorreu um erro ao adicionar o aluno na progressão");
          }
        } catch (error) {
          console.error("Erro ao enviar o arquivo:", error);
          setUploading(false);
          Alert("Erro", "Ocorreu um erro ao adicionar a progressão. Tente novamente.");
        } finally {
          setUploading(false);
          window.location.reload();
        }
      }
    } else {
      Alert("Alert3", "Preencha todos os campos.");
    }
  }


  const handleDataInicioChange = (e) => {
    const newDataInicio = e.target.value;
    setDataInicio(newDataInicio);
    const fim = (dataFim !== "" ? format(parseISO(dataFim), "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd"))

    // Automatically set data_fim to 15 days after data_inicio
    if (new Date(newDataInicio) >= new Date(fim)) {
      const newDataFim = format(addDays(parseISO(newDataInicio), 1), "yyyy-MM-dd");
      setDataFim(newDataFim);
    }
  };

  const handleDataFimChange = (e) => {
    const newDataFim = e.target.value;
    if (dataInicio && newDataFim >= dataInicio) {
      setDataFim(newDataFim);
    } else {
      // If data_fim is before data_inicio, set it to data_inicio
      setDataFim(dataInicio);
    }
  };

  const normalizeString = useCallback((str) => {
    if (str == null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }, []);

  const filteredAlunos = alunos.filter((aluno) => {
    const searchRMNormalized = normalizeString(searchRm);
    const searchNomeNormalized = normalizeString(searchNome);

    const alunoRM = normalizeString(aluno.rm?.toString() || "");
    const alunoNome = normalizeString(aluno.nome_aluno || "");

    return (
      alunoRM.includes(searchRMNormalized) &&
      alunoNome.includes(searchNomeNormalized)
    );
  });

  return (
    <Background>
      <Cardo $steps={steps}>
        <DivTitle><Title>Adicionar Progressão Parcial</Title><Title>{steps}/2</Title></DivTitle>
        {steps == 1 ? (
          <>
            <FormDiv>
              <InputSelect
                Style1={{ color: '#1a2e35' }}
                Style2={{ width: '20vw', color: '#fff', backgroundColor: '#1a2e35', border: "2px solid #1a2e35", paddingLeft: '10px', paddingRight: '25px' }}
                Style3={{ color: '#fff', backgroundColor: '#1a2e35' }}
                Label={"Cursos"}
                Options={cursos}
                Default={selectedCursoId && selectedCursoNome ? selectedCursoId + ',' + selectedCursoNome : "-"}
                OnChange={(e) => {
                  const [id, nome] = e.target.value.split(',');
                  setSelecionarAluno([])
                  setSelectedCursoId(id);
                  setSelectedCursoNome(nome);
                }}
              />
              <InputSelect
                Style1={{ color: '#1a2e35' }}
                Style2={{ width: '20vw', color: '#fff', backgroundColor: '#1a2e35', border: "2px solid #1a2e35", paddingLeft: '10px', paddingRight: '25px' }}
                Style3={{ color: '#fff', backgroundColor: '#1a2e35' }}
                Label={"Turmas"}
                Options={turmas}
                Default={selectedTurmaId && selectedTurmaNome ? selectedTurmaId + ',' + selectedTurmaNome : "-"}
                OnChange={(e) => {
                  const [id, nome] = e.target.value.split(',');
                  setSelecionarAluno([])
                  setSelectedTurmaId(id);
                  setSelectedTurmaNome(nome);
                }}
              />
              <InputSelect
                Style1={{ color: '#1a2e35' }}
                Style2={{ width: '20vw', color: '#fff', backgroundColor: '#1a2e35', border: "2px solid #1a2e35", paddingLeft: '10px', paddingRight: '25px' }}
                Style3={{ color: '#fff', backgroundColor: '#1a2e35' }}
                Label={"Disciplinas"}
                Options={disciplinas}
                Default={selectedDisciplinaId && selectedDisciplinaNome ? selectedDisciplinaId + ',' + selectedDisciplinaNome : "-"}
                OnChange={(e) => {
                  const [id, nome] = e.target.value.split(',');
                  setSelecionarAluno([])
                  setSelectedDisciplinaId(id);
                  setSelectedDisciplinaNome(nome);
                }}
              />
              {selectedDisciplinaId == 0 ? null :
                <ButtonSearch
                  type='button'
                  onClick={(e) => {
                    e.preventDefault();
                    ProcurarAlunos(selectedCursoId);
                  }}
                  disabled={selectedDisciplinaId == 0 || selectedDisciplinaId == '' || !selectedDisciplinaId ? true : false}
                >Carregar Alunos</ButtonSearch>
              }
            </FormDiv>
            <ContainerTable>
              {!alunos.length ? (
                <>
                  <Table>
                    <Theader>
                      <tr>
                        <Th>Rm</Th>
                        <Th>Nome</Th>
                        {/* <Th>Data Nascimento</Th>
                          <Th>Data Início</Th>
                          <Th>Data Término</Th> */}
                        <Th>Curso</Th>
                        <Th>Convênio</Th>
                        <Th><p>Selecionar</p> Aluno</Th>

                      </tr>
                    </Theader>
                  </Table>
                  <DivText>Selecione todos os campos acima</DivText>
                </>
              ) : (
                <Table>
                  <Theader>
                    <tr>
                      {alunos.length > 10 ? (
                        <>
                          <Th>
                            <Grup>
                              <Pesquisar
                                width={'10rem'}
                                color="white"
                                placeholder="RM"
                                name="rm"
                                placeholdercolor="white"
                                event={(e) => setSearchRm(e.target.value)}
                              />
                            </Grup>
                          </Th>
                          <Th>
                            <Grup>
                              <Pesquisar
                                width={'20rem'}
                                color="white"
                                placeholder="Nome"
                                name="nome"
                                placeholdercolor="white"
                                event={(e) => setSearchNome(e.target.value)}
                              />
                            </Grup>
                          </Th>
                        </>
                      ) : (
                        <>
                          <Th>RM</Th>
                          <Th>Nome</Th>
                        </>
                      )}
                      {/* <Th>Data Nascimento</Th>
                        <Th>Data Início</Th>
                        <Th>Data Término</Th> */}
                      <Th>Curso</Th>
                      <Th>Convênio</Th>
                      <Th><p>Selecionar</p> Aluno</Th>
                    </tr>
                  </Theader>
                  <Tbody>
                    {filteredAlunos?.map((d, i) => (
                      <Trbody key={i}>
                        <>
                          <Td>{d?.rm}</Td>
                          <Td>{d?.nome_aluno}</Td>
                          {/* <Td>{format(new Date(d?.dt_nascimento), "dd/MM/yyyy")}</Td>
                            <Td>{format(new Date(d?.dt_inicio), "dd/MM/yyyy")}</Td>
                            <Td>{format(new Date(d?.dt_termino), "dd/MM/yyyy")}</Td> */}
                          <Td>{d?.nome_curso}</Td>
                          <Td>{d?.convenio}</Td>
                          <Td>
                            <CheckBox
                              // label="Selecionar Aluno"
                              flexdirection={'column'}
                              checked={selecionarAluno.some((selectedAluno) => selectedAluno.id === d.id && selectedAluno.rm === d.rm)}
                              event={() => Selecionar(d.id, d.rm)}
                            />
                          </Td>
                        </>
                      </Trbody>
                    ))}
                  </Tbody>
                </Table>
              )
              }
            </ContainerTable>
            <DivSave>
              <Button
                event={() => setShow(false)}
                width="12%"
                placeholder={"fechar"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
              />
              <Button
                event={() => {
                  if (selecionarAluno.length > 0) setSteps(2)
                  else Alert('Alert2', 'Selecione um aluno ou mais')
                }}
                width="12%"
                placeholder={"Proximo"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
              // disabled={disabled || selectedDisciplinaId == 0}
              />
            </DivSave>
          </>
        ) : steps == 2 ? (
          <>
            <FormDiv>
              <Input
                color="#000000"
                widthDiv='12vw'
                placeholdercolor="#000000"
                type="date"
                placeholder="Início"
                required={true}
                value={dataInicio !== '' ? format(parseISO(dataInicio), "yyyy-MM-dd") : tomorrow}
                min={tomorrow}
                event={handleDataInicioChange}
              />
              <Input
                color="#000000"
                widthDiv='12vw'
                placeholdercolor="#000000"
                type="date"
                placeholder="Término"
                required={true}
                value={dataFim !== '' ? format(parseISO(dataFim), "yyyy-MM-dd") : (dataInicio !== '' ? dataInicio : afterTomorrow)}
                min={(dataInicio !== "" ? format(addDays(parseISO(dataInicio), 1), "yyyy-MM-dd") : '') || afterTomorrow}
                event={handleDataFimChange}
              />
              <DivTextarea>
                <label htmlFor="">Observações para o Aluno <small>(opcional)</small></label>
                <InputTextarea
                  type="text"
                  rows={4.5}
                  placeholder='Escreva um texto contendo orientações para os alunos realizarem a progressão.'
                  value={orientacoes}
                  maxLength={150}
                  onChange={(e) => setOrientacoes(e.target.value)}
                />
                <span>{orientacoes?.length ?? 0}/150</span>
              </DivTextarea>
            </FormDiv>
            {fileUrl ? <DivMain> <PdfViewer src={fileUrl} title="PDF Viewer" /> </DivMain> :
              <>
                <DivMain>
                  <DragAndDropArea
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDragActive(true);
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setDragActive(false);
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const droppedFile = e.dataTransfer.files[0];
                      if (droppedFile && droppedFile.type === "application/pdf") {
                        setFile(droppedFile);
                        setFileUrl(URL.createObjectURL(droppedFile));
                      }
                      setDragActive(false)
                    }}
                    dragActive={dragActive}
                  >
                    <HiddenFileInput
                      type="file"
                      accept=".pdf"
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        if (selectedFile && selectedFile.type === "application/pdf") {
                          setFile(selectedFile);
                          setFileUrl(URL.createObjectURL(selectedFile));
                        }
                      }}
                    />
                    {file ? null : (
                      <Instructions>
                        {dragActive
                          ? "Solte o arquivo aqui..."
                          : `Arraste (ou clique aqui) um arquivo em PDF contendo o conteúdo das atividades que os alunos 
                          devem realizar para cumprir a progressão parcial. Não esqueça de definir corretamente as datas 
                          acima correspondentes ao início e fim da progressão.`}
                      </Instructions>
                    )}
                  </DragAndDropArea>
                </DivMain>
              </>
            }
            <DivSave>
              <Button
                event={() => {
                  setSteps(1);
                }}
                width="12%"
                placeholder={"voltar"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
              />
              {fileUrl ?
                <DragAndDropArea2>
                  <FileInfo>
                    <span>{file.name}</span>
                    <FileDetails>
                      Tamanho: {formatFileSize(file.size)}
                    </FileDetails>
                  </FileInfo>
                  <Button
                    event={() => {
                      setFile(null);
                      setFileUrl(null);
                    }}
                    placeholder={"Excluir"}
                    backgroundcolor="#1a2e35"
                    borderRadius="10px"
                  />
                </DragAndDropArea2>
                : null
              }
              <Button
                event={handleUploadClick}
                width="12rem"
                placeholder={uploading ? "Adicionando..." : "Adicionar"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
                disabled={uploading}
              />
            </DivSave>
          </>
        ) : null}
      </Cardo>
    </Background >
  );
};

export default CardAdicionar;
