import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: ${(props) => (props.Show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow-x: hidden;

  &:before {
    z-index: 1;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--blur-bg-color);
    position: fixed;
    top: 0;
    left: 0;
  }
`

export const Container = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: column;
  z-index: 99;
  width: fit-content;
  gap: 0.5rem;
  border-radius: 15px;
  height: fit-content;
  background-color: var(--background);
  box-shadow: 2px 2px 40px rgba(0, 0, 0, 0.5);
  padding: 10px;
`

export const Close = styled.div`
  width: fit-content;
  height: fit-content;
  transition: transform 0.2s;

  &:hover{
    transform: scale(1.01);
    cursor: pointer;
  }
`

export const Header = styled.div`
  font-size: 2rem;
  text-align: center;
  color: var(--azul);
  padding: 10px;

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }
`

export const Form = styled.form`   
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  gap: 3rem;
  width: 100%;
  margin-bottom: 1rem;
`

export const Field = styled.div`
display: flex;
flex-direction: column;
gap: 0.5rem;
align-items: center;
`