import Slw from "sweetalert2"

export async function Alert(tipo, mensagem, titulo) {
  if (tipo == "Erro") {
    return await Slw.fire({
      title: "Erro",
      text: `${mensagem}`,
      icon: "error",
      iconColor: "#ff0000",
      confirmButtonColor: "var(--azul)",
      confirmButtonText: "Confirmar",
    })
  } else if (tipo == "Erro2") {
    return await Slw.fire({
      title: "Erro",
      text: `${mensagem}`,
      icon: "error",
      iconColor: "#ff0000",
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    })
  } else if (tipo == "Erro3") {
    return await Slw.fire({
      title: "Erro",
      text: `${mensagem}`,
      icon: "error",
      iconColor: "#ff0000",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    })
  } else if (tipo == "Sucesso") {
    return await Slw.fire({
      title: "Sucesso",
      text: `${mensagem}`,
      icon: "success",
      iconColor: "#008000",
      confirmButtonColor: "var(--azul)",
      confirmButtonText: "Confirmar",
    })
  } else if (tipo == "Sucesso2") {
    return await Slw.fire({
      title: "Sucesso",
      text: `${mensagem}`,
      icon: "success",
      iconColor: "#008000",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    })
  } else if (tipo == "Sucesso3") {
    return await Slw.fire({
      title: "Sucesso",
      text: `${mensagem}`,
      icon: "success",
      iconColor: "#008000",
      showConfirmButton: false,
      timer: 1200,
      timerProgressBar: true,
    })
  } else if (tipo == "Alert") {
    return await Slw.fire({
      title: "Alerta",
      text: `${mensagem}`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#ff735c",
      iconColor: "#ffae00",
      confirmButtonColor: "var(--azul)",
      confirmButtonText: "Confirmar",
    })
  } else if (tipo == "Alert2") {
    return await Slw.fire({
      title: "Alerta",
      text: `${mensagem}`,
      icon: "warning",
      iconColor: "#ffae00",
      showCancelButton: false,
      confirmButtonColor: "var(--azul)",
      confirmButtonText: "OK",
    })
  } else if (tipo == "Alert3") {
    return await Slw.fire({
      title: "Alerta",
      text: `${mensagem}`,
      icon: "warning",
      iconColor: "#ffae00",
      showConfirmButton: false,
      timer: 2000,
      timerProgressBar: true,
    })
  } else if (tipo == "Info") {
    return await Slw.fire({
      title: titulo || 'Informação',
      text: `${mensagem}`,
      icon: "info",
      // iconColor: "#ffae00",
      showCancelButton: false,
      confirmButtonColor: "var(--azul)",
      confirmButtonText: "OK",
    })
  }
}
