import React, { useState, useEffect } from 'react';
import {
  Background, DivSave, Title, Cardo, DivMain,
  DragAndDropArea, HiddenFileInput, FileInfo, FileDetails, Instructions, DivTitle, PdfViewer,
  Card,
  ContentCard,
  DivCard
} from './style';
import { BsFileWordFill } from "react-icons/bs";
import { RiFileWordFill } from "react-icons/ri";
import { LiaFileUploadSolid } from "react-icons/lia";
import Loading from "../../../utils/Loading/Loading";
import Button from '../../Button';
import { FaFileUpload, FaFileWord } from 'react-icons/fa';
import { Alert } from '../../../utils/Alert';
import api from '../../../Services/api';
import { Url_Docs } from "../../../assets/URL'S/Url_Docs";

const InsirirVerDocExemplar = ({ show, setShow, perfil }) => {
  if (!show) return null;

  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [deletando, setDeletando] = useState(false);
  const [escolha, setEscolha] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [viewFileUrl, setViewFileUrl] = useState(""); // Estado para armazenar a URL do arquivo a ser visualizado

  useEffect(() => {
    if (!show) { return null; }
    if (escolha === 2) {
      fetchFiles(); // Busca os arquivos do backend ao escolher "Ver Arquivos"
    }
    if (perfil === 'prof') {
      setEscolha(2);
    }

    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [show, escolha]);

  const fetchFiles = async () => {
    setLoading(true);
    try {
      const response = await api.get(Url_Docs()); // Fazendo a requisição para a rota '/docs'
      setFiles(response.data.arquivos); // Armazena a lista de arquivos na state
    } catch (error) {
      console.error("Erro ao buscar os arquivos:", error);
      Alert("Erro", "Não foi possível carregar os arquivos.");
    } finally {
      setLoading(false);
    }
  };

  const PC = windowSize.width >= 1000;
  const Pocket = windowSize.width <= 600;

  function Fechar(e) {
    e.preventDefault();
    setFile(null);
    setDragActive(false);
    setEscolha(null);
    setLoading(false);
    setShow(false);
    setViewFileUrl(""); // Fechar visualização de documento
  }

  async function Enviar(e) {
    e.preventDefault();
    if (file) {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append("file", file);
        const response = await api.post("/coordenador/enviar-docx-exemplar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        await Alert("Sucesso2", response.data.mensagem || 'Sucesso ao salvar o arquivo!')
      } catch (error) {
        console.error("Erro ao enviar o arquivo:", error);
        setUploading(false);
        Alert("Erro", error.response.data.mensagem || 'Erro ao salvar o arquivo!');
      } finally {
        setEscolha(null);
        setFile(null);
        setFileUrl(null);
        setFileSize(null);
        setViewFileUrl("");
        setUploading(false);
        // window.location.reload();
      }
    }
  }

  function DeletarDocx(e, fileName) {
    Alert("Alert", `Deseja deletar o arquivo: ${fileName}!`)
      .then(async (result) => {
        if (result.isConfirmed) {
          setDeletando(true);
          e.preventDefault();
          try {
            const response = await api.delete(`/coordenador/deletar-docx-exemplar`, {
              params: { fileName }
            })
            return await Alert("Sucesso2", response.data.mensagem || 'Sucesso ao deletar o arquivo!')
          } catch (error) {
            console.error("Erro ao deletar o arquivo:", error);
            return Alert("Erro", error.response.data.mensagem || 'Erro ao deletar o arquivo!');
          } finally {
            setDeletando(false);
            const response = await api.get(Url_Docs()); // Fazendo a requisição para a rota '/docs'
            setFiles(response.data.arquivos);
            // window.location.reload();
          }
        }
      })
  };


  function formatFileSize(size) {
    const units = ["B", "KB", "MB", "GB", "TB"];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return `${size.toFixed(2)} ${units[index]}`;
  }

  const sanitizeFilename = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD") // Normaliza a string separando os acentos
      .replace(/[\u0300-\u036f]/g, "") // Remove os acentos
  };

  const handleFileChange = (selectedFile) => {
    if (selectedFile && selectedFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      const sanitizedName = sanitizeFilename(selectedFile.name); // Nome sanitizado
      const newFile = new File([selectedFile], sanitizedName, { type: selectedFile.type }); // Novo File com o nome sanitizado

      setFile(newFile); // Define o novo arquivo
      setFileUrl(URL.createObjectURL(newFile)); // Atualiza a URL do arquivo
      setFileSize(formatFileSize(newFile.size)); // Atualiza o tamanho do arquivo
    } else {
      Alert('Erro', 'Por favor, selecione um arquivo .docx válido.');
    }
  };

  const handleDownload = async (fileName) => {
    try {
      const response = await api.get(Url_Docs(fileName), { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao baixar o arquivo:", error);
      Alert("Erro", "Não foi possível baixar o arquivo.");
    }
  };

  const handleView = (fileName) => {
    const url = Url_Docs(fileName); // Obter URL base do ambiente atual
    setViewFileUrl(`https://docs.google.com/gview?url=${url}&embedded=true`);
  };

  return (

    <Background>
      <Cardo $escolha={escolha}>
        <DivTitle><Title>Documento de Progressão Parcial</Title></DivTitle>
        <DivMain $escolha={escolha}>
          {loading ? <Loading /> : (
            <>
              {(escolha === null && perfil === 'coord') && (
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10%', width: "100%" }}>
                  <Button
                    type="button"
                    width="11rem"
                    height="100%"
                    placeholder="Inserir Novo Documento"
                    event={() => setEscolha(1)}
                  />
                  <Button
                    type="button"
                    width="10rem"
                    height="100%"
                    placeholder="Vizualizar Documentos"
                    event={() => setEscolha(2)}
                  />
                </div>
              )}
              {(escolha === 1 && perfil === 'coord') && (
                <>
                  {file ? (
                    <FileInfo>
                      <FaFileWord size={'25rem'} color='#0c44be4b' style={{ position: 'absolute' }} />
                      <div>
                        <span>Arquivo: {file.name}</span>
                        <FileDetails>
                          Tamanho: {fileSize}
                        </FileDetails>
                      </div>
                      {file && (
                        <Button
                          width={Pocket ? '4rem' : '10rem'}
                          event={() => {
                            setFile(null);
                            setFileUrl(null);
                            setFileSize(null);
                          }}
                          placeholder={"Remover"}
                          backgroundcolor="#1a2e35"
                          borderRadius="10px"
                          padding={!PC && '10px'}
                          fontSize={!PC && '12px'}
                        />
                      )}
                    </FileInfo>
                  ) : (
                    <DragAndDropArea
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDragActive(true);
                      }}
                      onDragLeave={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setDragActive(false);
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const droppedFile = e.dataTransfer.files[0];
                        handleFileChange(droppedFile);
                        setDragActive(false);
                      }}
                      dragActive={dragActive}
                    >
                      <HiddenFileInput
                        style={{ position: 'absolute', zIndex: 10 }}
                        type="file"
                        accept=".docx"
                        onChange={(e) => handleFileChange(e.target.files[0])}
                      />
                      <Instructions>
                        {dragActive
                          ? "Solte o arquivo aqui..."
                          : (
                            <>
                              <FaFileUpload size={PC ? '25rem' : '10rem'} color='#7c7c7c30' style={{ position: 'absolute' }} />
                              Arraste (ou clique aqui) um arquivo em .DOCX (Word) do modelo da progressão parcial
                            </>
                          )
                        }
                      </Instructions>
                    </DragAndDropArea>
                  )}
                </>
              )}
              {escolha === 2 && (
                <>
                  {viewFileUrl && (
                    <PdfViewer
                      src={viewFileUrl}
                      title="Visualizador de Documento"
                    />
                  )}
                  {files.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10%', width: "100%", height: '100%' }}>
                      <h3>Nenhum arquivo.</h3>
                    </div>
                  ) : (
                    <>
                      <DivCard>
                        {files.map((fileName, index) => (
                          <Card key={index}>
                            <div className='DivIcon'>
                              <span>{fileName.replace(/[_-]/g, ' ') || fileName}</span>
                              <BsFileWordFill className='icon' />
                            </div>
                            <div className='DivButton'>
                              <Button
                                type="button"
                                width={perfil === 'prof' ? "10rem" : "6rem"}
                                placeholder="Baixar"
                                event={() => handleDownload(fileName)}
                                padding={'10px'}
                                fontSize={'12px'}
                              />
                              {perfil !== 'prof' && (
                                <Button
                                  type="button"
                                  width={"6rem"}
                                  placeholder="Deletar"
                                  event={(e) => DeletarDocx(e, fileName)}
                                  padding={'10px'}
                                  fontSize={'12px'}
                                  disabled={deletando}
                                />
                              )}
                            </div>
                          </Card>
                        ))}
                      </DivCard>
                      {/* <DivCard>
                        {files.map((fileName, index) => (
                          <Card key={index}>
                            <div className='icon'>
                              <BsFileWordFill />
                            </div>
                            <div className='content'>
                              <span>{fileName.replace(/[_-]/g, ' ') || fileName}</span>  
                              <Button
                                type="button"
                                width="10rem"
                                placeholder="Baixar"
                                event={() => handleDownload(fileName)}
                              />
                            </div>
                          </Card>
                        ))}
                      </DivCard> */}
                    </>
                  )}
                </>
              )}
              {/* Se houver uma URL de arquivo para visualização, renderiza o iframe */}
            </>
          )}
        </DivMain>
        <DivSave>
          {escolha !== null && perfil !== 'prof' ? (
            <Button
              event={() => {
                setEscolha(null);
                setFile(null);
                setFileUrl(null);
                setFileSize(null);
                setViewFileUrl(""); // Limpa a URL de visualização
              }}
              width={Pocket ? '6rem' : '10rem'}
              placeholder={"Voltar"}
              backgroundcolor="#1a2e35"
              borderRadius="10px"
              padding={!PC && '10px'}
              fontSize={!PC && '12px'}
            />
          ) : (
            <Button
              event={Fechar}
              width={Pocket ? '6rem' : '10rem'}
              placeholder={"Fechar"}
              backgroundcolor="#1a2e35"
              borderRadius="10px"
              padding={!PC && '10px'}
              fontSize={!PC && '12px'}
            />
          )}
          {file && (
            <Button
              width={Pocket ? '4rem' : '10rem'}
              event={Enviar}
              placeholder={uploading ? "Enviando..." : "Enviar"}
              backgroundcolor="#1a2e35"
              borderRadius="10px"
              padding={!PC && '10px'}
              fontSize={!PC && '12px'}
              disabled={uploading}
            />
          )}
        </DivSave>
      </Cardo>
    </Background>
  );
};

export default InsirirVerDocExemplar;
