import React, { useState } from "react"
import { Background, Close, Container, Field, Form, Header } from "./style"
import Input from "../../Input"
import Icone from "../../../utils/Icone"
import Button from "../../Button"
import api from "../../../Services/api"
import { Alert } from "../../../utils/Alert"

const EsqueciSenhaModal = ({ Show, setShow }) => {
  const [rm, setRm] = useState("")
  const [email, setEmail] = useState("")
  const [nascimento, setNascimento] = useState("")

  const data = {
    rm: rm,
    email: email,
    dt_nascimento: nascimento,
  }

  function HandleSubmit() {
    const hoje = new Date().toLocaleString('pt-br')
    const ano = hoje.split(',')[0].split('/')[2]
    if (!data.rm) {
      Alert("Alert3", "Insira seu RM")
    } else if (!data.email) {
      Alert("Alert3", "Insira seu E-MAIL")
    } else if (!data.dt_nascimento) {
      Alert("Alert3", "Insira sua DATA DE NASCIMENTO")
    } else if (data.dt_nascimento.split('-')[0] > ano - 14 || data.dt_nascimento.split('-')[0] < ano - 80) {
      Alert("Alert3", "Insira uma DATA DE NASCIMENTO válida!")
    } else if (data.rm !== "" && data.email !== "" && data.dt_nascimento !== "") {
      api
        .put("/aluno/recuperar-senha", { data })
        .then((res) => {
          Alert("Sucesso", res.data.mensagem.split('.')[0])
        })
        .catch((err) => {
          console.log(err);
          Alert("Erro", `${err.response.data.erro == undefined ? "Erro interno de server" : err.response.data.erro.split(".")[0]}`)
        })
    } else {
      Alert("Erro2", 'Erro no servidor')
    }
  }

  if (Show == true) {
    return (
      <Background Show={Show}>
        <Container>
          <Header>
            <Close
              onClick={() => {
                setShow(false)
                setRm("")
                setEmail("")
                setNascimento("")
              }}
            >
              <Icone name={"X"} color={" var(--azul)"} size={32} />
            </Close>
            Recuperar sua senha
          </Header>
          <Form>
            <Field>
              <Input
                placeholdercolor="var(--azul)"
                placeholder="RM"
                event={(e) => {
                  setRm(e.target.value)
                }}
                color="var(--azul)"
              />
              <Input
                placeholdercolor="var(--azul)"
                placeholder="Email cadastrado"
                color="var(--azul)"
                event={(e) => {
                  setEmail(e.target.value)
                }}
              />
              <Input
                type="date"
                placeholdercolor="var(--azul)"
                placeholder="Data de Nascimento"
                color="var(--azul)"
                event={(e) => {
                  setNascimento(e.target.value)
                }}
              />
            </Field>
            <Button
              width="150px"
              height="52px"
              placeholder="Enviar"
              event={(e) => {
                e.preventDefault()
                HandleSubmit()
              }}
            />
          </Form>
        </Container>
      </Background>
    )
  } else {
    return null
  }
}

export default EsqueciSenhaModal
