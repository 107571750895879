import React from "react"
import { Container, Select, Labell } from "./style"

const InputSelect = ({ Label, Style1, Default, Disabled, Options, OnChange, Required, Style2, Style3 }) => {
  const defaultLabelStyle = { color: "#f0f0f0" };
  const defaultSelectStyle = { color: "#f0f0f0", backgroundColor: "var(--azul)" };
  const defaultOptionStyle = { color: "#f0f0f0", backgroundColor: "var(--azul)" };

  const labelStyle = { ...defaultLabelStyle, ...Style1 };
  const selectStyle = { ...defaultSelectStyle, ...Style2 };
  const optionStyle = { ...defaultOptionStyle, ...Style3 };

  return (
    <Container>
      <label style={labelStyle} htmlFor={Label}>
        {Label}
      </label>
      <Select
        value={Default}
        onChange={OnChange}
        required={Required}
        style={selectStyle}
        disabled={Disabled}
      >
        {Disabled && (
          <option disabled selected hidden>
            {Disabled}
          </option>
        )}
        {Options?.map((option) => (
          <option
            style={optionStyle}
            value={option.value}
            key={option.value}
            label={option.label}
          >
            {option.label}
          </option>
        ))}
      </Select>
    </Container>
  );
};

export default InputSelect
