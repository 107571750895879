import React, { useEffect, useState, useCallback } from 'react';
import { addDays, format, parseISO } from 'date-fns';
import Swal from 'sweetalert2';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";

import {
  Background, DivSave, Title, Cardo, ContainerTable, Table, Theader, Th, Tbody, Trbody, Td, DivMain, FormDiv, PdfViewer,
  DragAndDropArea, HiddenFileInput, FileInfo, FileDetails, Instructions, DragAndDropArea2, DivTitle, DivideTable, Infos,
  CursoTurmaDiscInfo, Theader2, DivText, DivText2, ThRm, Grup, ThNome,
  DivTextarea,
  InputTextarea
} from './style';
import './styleCard.css';
import api from '../../../Services/api';
import Input from '../../Input';
import { Url_Pdf, Url_Pdf_Info } from '../../../assets/URL\'S/Url_Pdf';
import Button from '../../Button';
import CheckBox from '../../Checkbox';
import { Alert } from '../../../utils/Alert';
import Pesquisar from '../../Pesquisar';
import Loading from '../../../utils/Loading/Loading';

const CardEditarProgressao = ({ show, setShow, progressaoEdit }) => {
  if (!show) { return null };
  const [alunos, setAlunos] = useState([]);
  const [mostrarAlunos, setMostrarAlunos] = useState(true);
  const [selecionarAluno, setSelecionarAluno] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [orientacoesEdit, setOrientacoesEdit] = useState(undefined);
  const [steps, setSteps] = useState(1);
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [fileUrlEdit, setFileUrlEdit] = useState(null);
  const [fileSize, setFileSize] = useState({ bytes: null, kb: null, mb: null, gb: null });
  const [searchRm, setSearchRm] = useState("");
  const [searchNome, setSearchNome] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      ListarAlunosProgressoes(progressaoEdit.id);
    }
  }, [show, progressaoEdit.id]);

  useEffect(() => {
    if (show && steps === 2) {
      setLoading3(true);
      const urlpdf = Url_Pdf(progressaoEdit.pdf);
      const urlpdfInfo = Url_Pdf_Info(progressaoEdit.pdf);
      setFileUrl(urlpdf);
      fetch(urlpdfInfo)
        .then(response => response.json())
        .then(data => {
          setFileSize({
            bytes: data.fileSizeInBytes,
            kb: data.fileSizeInKB,
            mb: data.fileSizeInMB,
            gb: data.fileSizeInGB
          });
        })
        .catch(error => {
          console.error('Erro ao fazer a requisição', error);
        }).finally(() => {
          setLoading3(false);
        });
    }
  }, [show, steps, progressaoEdit.pdf]);

  useEffect(() => {
    if (show) {
      if (mostrarAlunos) {
        setLoading2(true);
        ListarAlunos(progressaoEdit.curso_rh.split(', ')[0]);
      } else {
        setAlunos([]);
      }
    }
  }, [mostrarAlunos, selecionarAluno, show, progressaoEdit.curso_rh]);

  const ListarAlunosProgressoes = useCallback(async (id_progressao) => {
    try {
      const response = await api.get(`/professor/listar-alunos-progressoes?id_progressao=${id_progressao}`);
      const { alunosEmProgressao } = response.data;
      const alunosValidos = alunosEmProgressao.filter(Boolean);
      setSelecionarAluno(alunosValidos.flat());
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: "Erro",
        text: err.response?.data?.erro || "Erro interno de servidor",
        icon: "error",
        iconColor: "#ff0000",
        confirmButtonColor: "var(--azul)",
        confirmButtonText: "Confirmar",
      }).then((result) => { if (result.isConfirmed) setShow(false) });
    } finally {
      setLoading(false);
    }
  }, [setShow, loading]);

  const ListarAlunos = useCallback((CursoId) => {
    if (CursoId) {
      api.get(`/professor/listar-alunos?id=${CursoId}`)
        .then(async (response) => {
          const data = await response.data;
          if (data.length !== 0) {
            const alunosFiltrados = await data.filter((aluno) =>
              !selecionarAluno.some((selected) =>
                selected.rm === aluno.rm || selected.id === aluno.id
              )
            );
            setAlunos(alunosFiltrados);
          } else {
            setAlunos([]);
          }
        })
        .catch((err) => {
          setAlunos([]);
          console.error(err);
        }).finally(() => {
          setLoading2(false);
        })
    }
  }, [selecionarAluno, loading2]);

  const Selecionar = useCallback((aluno) => {
    setSelecionarAluno((prevState) =>
      prevState.some((selected) => selected.id === aluno.id && selected.rm === aluno.rm)
        ? prevState.filter((selected) => selected.id !== aluno.id || selected.rm !== aluno.rm)
        : [...prevState, aluno]
    );
  }, []);

  const handleFileChange = useCallback((e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setFile(file);
      const reader = new FileReader();
      reader.onload = () => setFileUrlEdit(reader.result);
      reader.readAsDataURL(file);
    } else {
      Swal.fire("Erro", "Apenas arquivos PDF são permitidos", "error");
    }
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setDragActive(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragActive(false);
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    setDragActive(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === "application/pdf") {
      setFile(droppedFile);
      setFileUrlEdit(URL.createObjectURL(droppedFile));
    }
  }, []);

  const formatFileSize = useCallback((size) => {
    if (size < 1024) return size + ' bytes';
    else if (size < 1048576) return (size / 1024).toFixed(1) + ' KB';
    else if (size < 1073741824) return (size / 1048576).toFixed(1) + ' MB';
    else return (size / 1073741824).toFixed(1) + ' GB';
  }, []);

  const Fechar = useCallback((e) => {
    e.preventDefault();
    setAlunos([]);
    setSelecionarAluno([]);
    setDataInicio("");
    setDataFim("");
    setShow(false);
  }, [setShow]);

  const handleEditSave = useCallback(async () => {
    const result = await Swal.fire({
      title: "Alerta",
      text: "Deseja salvar a Edição?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#ff735c",
      iconColor: "#ffae00",
      confirmButtonColor: "var(--azul)",
      confirmButtonText: "Confirmar",
    });

    if (result.isConfirmed) {
      const Inicio = dataInicio || format(parseISO(progressaoEdit?.data_inicio), "yyyy-MM-dd");
      const Fim = dataFim || format(parseISO(progressaoEdit?.data_fim), "yyyy-MM-dd");
      const Orientacoes = orientacoesEdit != undefined ? orientacoesEdit : progressaoEdit?.orientacoes;
      const hojeUsa = new Date().toISOString().split('T')[0];

      const data = {
        id: progressaoEdit.id,
        data_inicio: Inicio,
        data_fim: Fim,
        id_pdf: progressaoEdit.pdf,
        orientacoes: Orientacoes
      };

      // Date validations
      const dataInicioObj = new Date(data.data_inicio);
      const dataFimObj = new Date(data.data_fim);

      if ((dataInicioObj > dataFimObj) || (dataInicioObj.toISOString().split('T')[0] === dataFimObj.toISOString().split('T')[0])) {
        return Swal.fire({
          title: "Erro",
          html: `<p>Insira uma <strong>Data de Término</strong> maior que <strong>Data de Início</strong></p>`,
          icon: "error",
          iconColor: "red",
          confirmButtonColor: "var(--azul)",
          confirmButtonText: "Confirmar",
        });
      }
      try {
        setUploading(true);
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);

        await api.put("/professor/editar-progressao", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        await Promise.all(
          selecionarAluno.map(async (aluno) => {
            try {
              return await api.put("/professor/editar-alnuos-progressao", {
                rm: aluno.rm,
                id_progressao: data.id,
                id_aluno: aluno.id,
              });
            } catch (err) {
              console.error(err);
              return Alert("Erro", "Ocorreu um erro ao inserir o aluno na progressão");
            }
          })
        );

        await Alert("Sucesso2", "As alterações foram salvas com sucesso!")
      } catch (error) {
        console.error("Erro ao enviar o arquivo:", error);
        setUploading(false);
        Alert("Erro", "Ocorreu um erro ao salvar as alterações. Tente novamente.");
      } finally {
        setUploading(false);
        window.location.reload();
      }
    }
  }, [dataInicio, dataFim, progressaoEdit, file, selecionarAluno, orientacoesEdit]);

  const normalizeString = useCallback((str) => {
    if (str == null) {
      return "";
    }
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  }, []);

  const filteredAlunos = alunos.filter((aluno) => {
    const searchRMNormalized = normalizeString(searchRm);
    const searchNomeNormalized = normalizeString(searchNome);

    const alunoRM = normalizeString(aluno.rm?.toString() || "");
    const alunoNome = normalizeString(aluno.nome_aluno || "");

    return (
      alunoRM.includes(searchRMNormalized) &&
      alunoNome.includes(searchNomeNormalized)
    );
  });

  const inicio = new Date(progressaoEdit?.data_inicio)
  const NaoComecou = new Date() <= inicio


  const tomorrow = format(addDays(new Date(), 1), "yyyy-MM-dd");

  const handleDataInicioChange = (e) => {
    const newDataInicio = e.target.value;
    setDataInicio(newDataInicio);
    const fim = (dataFim !== "" ? format(parseISO(dataFim), "yyyy-MM-dd") : format(parseISO(progressaoEdit?.data_fim?.split("T")[0]), "yyyy-MM-dd"))

    // Automatically set data_fim to 15 days after data_inicio
    if (new Date(newDataInicio) >= new Date(fim)) {
      const newDataFim = format(addDays(parseISO(newDataInicio), 1), "yyyy-MM-dd");
      setDataFim(newDataFim);
    }
  };

  const turma = progressaoEdit?.turma_rh?.split(',')[1].split('-').slice(1).join(' - ');
  const curso = progressaoEdit?.curso_rh?.split(',')[1].replace(/(TÉCNICO EM|TECNÓLOGO EM)/g, '').trim();

  return (
    <Background>
      <Cardo $steps={steps}>
        <DivTitle><Title>Editar Progressão Parcial</Title><Title>{steps}/2</Title></DivTitle>
        {steps === 1 ? (
          <>
            <FormDiv>
              <Infos>
                <CursoTurmaDiscInfo>curso <p>{curso}</p></CursoTurmaDiscInfo>
                <CursoTurmaDiscInfo>turma <p>{turma}</p></CursoTurmaDiscInfo>
                <CursoTurmaDiscInfo>disciplina <p>{progressaoEdit.disciplina_rh.split(', ')[1]}</p></CursoTurmaDiscInfo>
              </Infos>
            </FormDiv>
            {loading ? (
              <ContainerTable $loading={loading}>
                <Loading />
              </ContainerTable>
            ) : (
              <ContainerTable>
                {selecionarAluno.length > 0 ? (
                  <Table>
                    <Theader>
                      <tr>
                        <Th>Rm</Th>
                        <Th>Nome</Th>
                        <Th>Curso</Th>
                        <Th>Convênio</Th>
                        <Th><p>Selecionar</p> Aluno</Th>
                      </tr>
                    </Theader>
                    <Tbody>
                      {selecionarAluno.map((d, i) => (
                        <Trbody key={i}>
                          <Td>{d?.rm}</Td>
                          <Td>{d?.nome_aluno}</Td>
                          <Td>{d?.nome_curso}</Td>
                          <Td>{d?.convenio}</Td>
                          <Td>
                            <CheckBox
                              flexdirection={'column'}
                              checked={selecionarAluno.some((selectedAluno) => selectedAluno.id === d.id && selectedAluno.rm === d.rm)}
                              event={() => Selecionar(d)}
                            />
                          </Td>
                        </Trbody>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <>
                    <Table>
                      <Theader>
                        <tr>
                          <Th>Rm</Th>
                          <Th>Nome</Th>
                          <Th>Curso</Th>
                          <Th>Convênio</Th>
                          <Th><p>Selecionar</p> Aluno</Th>
                        </tr>
                      </Theader>
                    </Table>
                    <DivText>Nenhum aluno selecionado</DivText>
                  </>
                )}
                <DivideTable onClick={() => setMostrarAlunos(!mostrarAlunos)}>
                  {!mostrarAlunos ? (
                    <>
                      <IoIosArrowDropupCircle />Mostrar mais alunos<IoIosArrowDropupCircle />
                    </>
                  ) : (
                    <>
                      <IoIosArrowDropdownCircle />Mostrar menos alunos<IoIosArrowDropdownCircle />
                    </>
                  )}
                </DivideTable>
                {loading2 ? (
                  <DivText2>
                    <Loading />
                  </DivText2>
                ) : (
                  <>
                    {mostrarAlunos && !alunos.length ? (
                      <DivText2>Este curso não possui mais alunos</DivText2>
                    ) : (
                      <>
                        {alunos.length > 0 && (
                          <Table>
                            <Theader2>
                              <tr>
                                {alunos.length > 10 ? (
                                  <>
                                    <Th>
                                      <Grup>
                                        <Pesquisar
                                          width={'10rem'}
                                          color="white"
                                          placeholder="RM"
                                          name="rm"
                                          placeholdercolor="white"
                                          event={(e) => setSearchRm(e.target.value)}
                                        />
                                      </Grup>
                                    </Th>
                                    <Th>
                                      <Grup>
                                        <Pesquisar
                                          width={'20rem'}
                                          color="white"
                                          placeholder="Nome"
                                          name="nome"
                                          placeholdercolor="white"
                                          event={(e) => setSearchNome(e.target.value)}
                                        />
                                      </Grup>
                                    </Th>
                                  </>
                                ) : (
                                  <>
                                    <Th>RM</Th>
                                    <Th>Nome</Th>
                                  </>
                                )}
                                <Th>Curso</Th>
                                <Th>Convênio</Th>
                                <Th><p>Selecionar</p> Aluno</Th>
                              </tr>
                            </Theader2>
                            <Tbody>
                              {filteredAlunos.map((d, i) => (
                                <Trbody key={i}>
                                  <Td>{d?.rm}</Td>
                                  <Td>{d?.nome_aluno}</Td>
                                  <Td>{d?.nome_curso}</Td>
                                  <Td>{d?.convenio}</Td>
                                  <Td>
                                    <CheckBox
                                      flexdirection={'column'}
                                      checked={selecionarAluno.some((selectedAluno) => selectedAluno.id === d.id && selectedAluno.rm === d.rm)}
                                      event={() => Selecionar(d)}
                                    />
                                  </Td>
                                </Trbody>
                              ))}
                            </Tbody>
                          </Table>
                        )}
                      </>
                    )}
                  </>
                )}
              </ContainerTable>
            )}
            <DivSave>
              <Button
                event={Fechar}
                width="12%"
                placeholder={"fechar"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
              />
              <Button
                event={() => {
                  if (selecionarAluno.length > 0)
                    setSteps(2)
                  else
                    Alert('Alert2', 'Selecione um aluno ou mais')
                }}
                width="12%"
                placeholder={"Ver PDF"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
              />
            </DivSave>
          </>
        ) : steps === 2 ? (
          <>
            <FormDiv $steps={steps}>
              <Input
                color="#000000"
                widthDiv='12vw'
                placeholdercolor="#000000"
                type={"date"}
                placeholder={"Início"}
                value={
                  NaoComecou ? (
                    dataInicio !== ""
                      ? format(parseISO(dataInicio), "yyyy-MM-dd")
                      : format(parseISO(progressaoEdit?.data_inicio?.split("T")[0]), "yyyy-MM-dd")
                  ) : (
                    format(parseISO(progressaoEdit?.data_inicio?.split("T")[0]), "yyyy-MM-dd")
                  )}
                disabled={NaoComecou ? false : true}
                min={tomorrow}
                event={handleDataInicioChange}
              />
              <Input
                color="#000000"
                widthDiv='12vw'
                placeholdercolor="#000000"
                type={"date"}
                placeholder={"Término"}
                required={true}
                value={
                  dataFim !== ""
                    ? format(parseISO(dataFim), "yyyy-MM-dd")
                    : format(parseISO(progressaoEdit?.data_fim?.split("T")[0]), "yyyy-MM-dd")

                }
                min={
                  dataInicio !== ""
                    ? format(addDays(parseISO(dataInicio), 1), "yyyy-MM-dd")
                    : format(addDays(parseISO(progressaoEdit?.data_inicio?.split("T")[0]), 1), "yyyy-MM-dd")
                }
                event={(e) => setDataFim(e.target.value)}
              />
              <DivTextarea>
                <label htmlFor="">Observações para o Aluno <small>(opcional)</small></label>
                <InputTextarea
                  type="text"
                  rows={4.5}
                  placeholder='Observações para o Aluno.'//Exemplo:  saber onde enviar
                  value={orientacoesEdit == undefined ? progressaoEdit?.orientacoes : orientacoesEdit}
                  maxLength={150}
                  onChange={(e) => setOrientacoesEdit(e.target.value)}
                />
                <span>
                  {orientacoesEdit?.length ?? progressaoEdit?.orientacoes?.length ?? 0}/150
                </span>
              </DivTextarea>
            </FormDiv>
            {fileUrl ? (
              <DivMain>
                {loading3 ? (
                  <Loading />
                ) : (
                  <PdfViewer src={fileUrl} title="PDF Viewer" />
                )}
              </DivMain>
            ) : fileUrlEdit ? (
              <DivMain>
                <PdfViewer src={fileUrlEdit} title="PDF Viewer" />
              </DivMain>
            ) : (
              <DivMain>
                <DragAndDropArea onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
                  <Instructions>Arraste (ou clique aqui) um arquivo em PDF contendo o conteúdo das atividades que os alunos 
                          devem realizar para cumprir a progressão parcial. Não esqueça de definir corretamente as datas 
                          acima correspondentes ao início e fim da progressão.</Instructions>
                  <HiddenFileInput type="file" onChange={handleFileChange} />
                </DragAndDropArea>
              </DivMain>
            )}
            <DivSave>
              <Button
                event={() => setSteps(1)}
                width="12%"
                placeholder={"voltar"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
              />
              {fileUrl ? (
                <DragAndDropArea2>
                  <FileInfo>
                    <span>nome: {progressaoEdit.pdf}.PDF</span>
                    {fileSize.kb && (
                      <FileDetails>
                        Tamanho do arquivo: ({fileSize.kb} KB, {fileSize.mb} MB
                        {fileSize.gb > 0.01 ? `, ${fileSize.gb} GB` : ''})
                      </FileDetails>
                    )}
                  </FileInfo>
                  <Button
                    event={() => {
                      setFile(null);
                      setFileUrl(null);
                      setFileUrlEdit(null);
                    }}
                    placeholder={"Excluir"}
                    backgroundcolor="#1a2e35"
                    borderRadius="10px"
                  />
                </DragAndDropArea2>
              ) : fileUrlEdit ? (
                <DragAndDropArea2>
                  <FileInfo>
                    <span>{file.name}</span>
                    <FileDetails>
                      Tamanho: {formatFileSize(file.size)}
                    </FileDetails>
                  </FileInfo>
                  <Button
                    event={() => {
                      setFile(null);
                      setFileUrl(null);
                      setFileUrlEdit(null);
                    }}
                    placeholder={"Excluir"}
                    backgroundcolor="#1a2e35"
                    borderRadius="10px"
                  />
                </DragAndDropArea2>
              ) : null}
              <Button
                event={handleEditSave}
                width="12rem"
                placeholder={uploading ? "Salvando..." : "Salvar"}
                backgroundcolor="#1a2e35"
                borderRadius="10px"
                disabled={uploading}
              />
            </DivSave>
          </>
        ) : null}
      </Cardo>
    </Background>
  );
};

export default CardEditarProgressao;