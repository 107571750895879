import React, { useEffect, useState } from "react"
import {
  ButtonsResponsive, ButtonsResponsive2, ButtonVoice, Card, Container, ContainerResponsive, ErroMensage,
  Field, FieldResponsive, Forget, Form, FormResponsive, Heading, HeadingResponsive, Icon, InputDiv,
  SelectedPerfils,
} from "./style"
import idCardImage from "../../assets/images/proviso.jpg"
import Button from "../../Components/Button"
import Input from "../../Components/Input"
import api from "../../Services/api"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import EsqueciSenhaModal from "../../Components/modals/EsqueciSenha"
import ReCAPTCHA from "react-google-recaptcha";
import { TiMicrophone } from "react-icons/ti";
import axios from "axios"
import EscolhaAcesso from "../../Components/modals/Login/EscolhaAcesso"
import { Alert } from "../../utils/Alert"
import { FaRegQuestionCircle } from "react-icons/fa"
import ComoAcessar from "../../Components/modals/Login/ComoAcessar"
import ModalVoiceLogin from "../../Components/modals/VoiceLogin"

export default function Login() {
  const UpdateData = '07/10/2024';

  const breakpoint = 1050
  const [rm, setRm] = useState("")
  const [matricula, setMatricula] = useState("")
  const [senha, setSenha] = useState("")
  const [capt, setCapt] = useState('')
  const [rm2, setRm2] = useState(false)
  const [matricula2, setMatricula2] = useState(false)
  const [senha2, setSenha2] = useState(false)
  const [capt2, setCapt2] = useState(false)
  const [Ip, setIp] = useState("");
  const [aluno, setAluno] = useState(false)
  const [servidor, setServidor] = useState(false)
  const [modalVoice, setModalVoice] = useState(false)
  const [Show, setShow] = useState(false)
  const [showComoAcessar, setShowComoAcessar] = useState(false)
  const [showEscolha, setShowEscolha] = useState(false)
  const [multipleAlunos, setMultipleAlunos] = useState([]);
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const Navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const data = {
    nome_usuario: (aluno ? rm : servidor ? matricula : null),
    senha: senha,
    ipuser: Ip,
    perfil: (aluno ? 'aluno' : servidor ? 'servidor' : null),
    UpdateData
  }

  const getIpWithRetry = async (retries = 3, delay = 1000) => {
    try {
      const dataIp = await axios.get('https://ipapi.co/json');
      setIp(" ip: " + dataIp.data.ip + ", cidade: " + dataIp.data.city + ", pais: " + dataIp.data.country_name);
    } catch (error) {
      if (retries > 0 && error.response?.status === 429) {
        setTimeout(() => {
          getIpWithRetry(retries - 1, delay * 2);
        }, delay);
      } else {
        console.error('Erro ao obter o IP:', error);
      }
    }
  };

  const UrlAlunosETeste = ["https://alunos.fiecdev.com.br", "https://testecart.fiecdev.com.br"].some((url) => window.location.href.includes(url))

  useEffect(() => {
    if (UrlAlunosETeste) {
      if (!Ip) {
        getIpWithRetry(5, 2000);
      }
    }
    console.clear();
    localStorage.clear()
  }, []);

  useEffect(() => {
    if (aluno) {
      setMatricula('')
    }
    if (servidor) {
      setRm('')
    }
  }, [aluno, servidor])

  function HandleSingleUserResponse(resData) {
    console.log(resData);

    if (resData.erro) {
      if (resData.perfil === "aluno") {
        api.post(`/entrou`, { data: resData }).catch((err) => {
          console.log(err);
          return Alert('Erro', err?.response?.data?.erro.split('.')[0] || 'Erro interno do servidor.')
        });
      } else {
        return Alert('Erro', resData.erro.split('.')[0]);
      }
    } else {
      localStorage.setItem("token", resData.token);
      if (resData.perfil === "aluno") {
        api.post(`/entrou`, { data: resData }).then(() => {
          if (resData.mensagem.includes("Informe uma senha para o aluno.")) {
            localStorage.setItem("Entrada", "true")
            return Navigate("/aluno");
          }
          localStorage.setItem("token2", btoa(`${resData.aluno.id} ${resData.aluno.rm} ${resData.aluno.nome_aluno}`));
          Navigate("/aluno");
        })
      } else if (resData.perfil == "professor") {
        Navigate("/professor")
      } else if (resData.perfil == "coordenador") {
        Navigate("/coordenador")
      } else {
        localStorage.setItem("usuario", resData.nome_usuario);
        Navigate("/secretaria");
      }
    }
  };

  function HandleSubmit(e) {
    e.preventDefault();
    if (UrlAlunosETeste) {
      if (!capt) {
        setCapt2(true);
        return setTimeout(() => {
          setCapt2(false);
        }, 4000);
      }
    }
    if (!data.nome_usuario) {
      if (data.perfil == "servidor") {
        setMatricula2(true);
        return setTimeout(() => {
          setMatricula2(false);
        }, 4000);
      }
      setRm2(true);
      return setTimeout(() => {
        setRm2(false);
      }, 4000);
    } else if (!data.senha) {
      setSenha2(true);
      return setTimeout(() => {
        setSenha2(false);
      }, 4000);
    } else {
      if (data.nome_usuario.trim() !== "" && data.senha.trim() !== "") {
        api.post(`/entrar`, { data })
          .then(async (res) => {
            const resData = await res.data;
            if (typeof resData === 'object' && !resData.perfil && Object.keys(resData).length > 1) {
              const alunosArray = Object.values(resData);
              setMultipleAlunos(alunosArray);
              setShowEscolha(true);
            } else {
              HandleSingleUserResponse(resData);
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              title: "Erro",
              text: `${err.response?.data?.erro.split('.')[0] || "Erro interno do servidor"}`,
              icon: "error",
              iconColor: "red",
              confirmButtonColor: "var(--azul)",
              confirmButtonText: "Confirmar",
            });
          });
      }
    }
  };

  const handleAlunoSelection = (selectedAluno) => {
    HandleSingleUserResponse(selectedAluno);
    setShowEscolha(false);
  };

  const PROD_reCAPTCHA = '6LeJGQIqAAAAALYK6wg1y9UFu9GXFGbfES0EKbmN'
  const LOCAL_reCAPTCHA = '6Lc8EgIqAAAAAHYEGKRwCh_DPJ3P0JZqHZ-PcaBR'

  const renderForm = () => (
    <>
      <Form onSubmit={HandleSubmit}>
        {(aluno || servidor) ? (
          <>
            <Heading>
              <SelectedPerfils $aluno={aluno} $servidor={servidor}>
                <p className="aluno" onClick={() => { setAluno(true); setServidor(false); }}>aluno</p>
                <p className="serv" onClick={() => { setAluno(false); setServidor(true); }}>servidor</p>
              </SelectedPerfils>
              {aluno && (<p className="ComoAcessar" onClick={() => setShowComoAcessar(true)}>Primeiro Acesso <FaRegQuestionCircle /></p>)}
            </Heading>
            <Field>
              <InputDiv>
                {aluno ? (
                  <Input
                    value={rm}
                    placeholdercolor="var(--azul)"
                    color="var(--azul)"
                    type="text"
                    placeholder="RM"
                    required={true}
                    event={(e) => setRm(e.target.value)}
                  />
                ) : (
                  <Input
                    value={matricula}
                    placeholdercolor="var(--azul)"
                    color="var(--azul)"
                    type="text"
                    placeholder="Matrícula ou E-mail"
                    required={true}
                    event={(e) => setMatricula(e.target.value)}
                  />
                )}
                {(matricula2 || rm2) && <ErroMensage>Campo vazio</ErroMensage>}
              </InputDiv>
              <InputDiv>
                <Input
                  value={senha}
                  color="var(--azul)"
                  placeholdercolor="var(--azul)"
                  type="password"
                  placeholder="Senha"
                  required={true}
                  event={(e) => setSenha(e.target.value)}
                />
                {senha2 && <ErroMensage>Campo de Senha vazio</ErroMensage>}
              </InputDiv>
              {/* <ButtonVoice type='button' onClick={() => setModalVoice(true)}>
                <TiMicrophone
                  title="Aperte para falar seu rm e sua Senha"
                  size="2.5rem"
                />
                <p>Aperte para falar seu rm e sua Senha</p>
              </ButtonVoice> */}
              {((rm.length > 0 || matricula.length > 0) && senha.length > 0) && (
                <div style={{ height: windowSize.width < 1300 ? '9rem' : '5rem', overflow: 'hidden' }}>
                  <ReCAPTCHA
                    sitekey={window.location.href.includes("https://alunos.fiecdev.com.br") ? PROD_reCAPTCHA : LOCAL_reCAPTCHA}
                    size={windowSize.width < 1300 ? "compact" : "normal"}
                    onChange={(Val) => setCapt(Val)}
                  />
                </div>
              )}
              <Forget onClick={() => setShow(true)}>
                <p style={{ whiteSpace: "nowrap" }}>Esqueci minha senha</p>
                <Icon />
              </Forget>
              <ButtonsResponsive2>
                {capt2 && <ErroMensage>Resolva o Captcha</ErroMensage>}
                <Button
                  id="button"
                  whitespace="nowrap"
                  width="50%"
                  type="submit"
                  height="50px"
                  placeholder="Entrar"
                />
              </ButtonsResponsive2>
            </Field>
          </>
        ) : (
          <>
            <h2 style={{ display: 'flex', alignItems: 'center', justifyContent: "center", width: '100%', padding: 10 }}>Selecione seu Perfil</h2>
            <SelectedPerfils $aluno={aluno} $servidor={servidor}>
              <p className="aluno" onClick={() => { setAluno(true); setServidor(false); }}>aluno</p>
              <p className="serv" onClick={() => { setAluno(false); setServidor(true); }}>servidor</p>
            </SelectedPerfils>
          </>
        )}
      </Form>
    </>
  );

  if (windowSize.width < breakpoint) {
    return (
      <ContainerResponsive>
        <EsqueciSenhaModal Show={Show} setShow={setShow} />
        <ComoAcessar showComoAcessar={showComoAcessar} setShowComoAcessar={setShowComoAcessar} />
        <EscolhaAcesso
          showEscolha={showEscolha}
          setShowEscolha={setShowEscolha}
          multipleAlunos={multipleAlunos}
          setMultipleAlunos={setMultipleAlunos}
          handleAlunoSelect={handleAlunoSelection}
        />
        {/* <ModalVoiceLogin modalVoice={modalVoice} setModalVoice={setModalVoice} rm={rm} setRm={setRm} senha={senha} setSenha={setSenha} /> */}
        {renderForm()}
        <p className="update">Última Atualização: {UpdateData}</p>
      </ContainerResponsive>
    );
  }

  return (
    <>
      <ModalVoiceLogin modalVoice={modalVoice} setModalVoice={setModalVoice} rm={rm} setRm={setRm} senha={senha} setSenha={setSenha} />
      <EsqueciSenhaModal Show={Show} setShow={setShow} />
      <ComoAcessar showComoAcessar={showComoAcessar} setShowComoAcessar={setShowComoAcessar} />
      <EscolhaAcesso
        showEscolha={showEscolha}
        setShowEscolha={setShowEscolha}
        multipleAlunos={multipleAlunos}
        setMultipleAlunos={setMultipleAlunos}
        handleAlunoSelect={handleAlunoSelection}
      />
      <Card><img style={{ width: "160%", height: "110%" }} src={idCardImage} alt="Imagem do Login" /></Card>
      <Container>
        {renderForm()}
        <p className="update">Última Atualização: {UpdateData}</p>
      </Container>
    </>
  );
}
