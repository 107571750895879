import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80vh;

    
    @media (max-width: 800px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }
`

export const LeftDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 800px) {
        width: 80%;
    }
`

export const RightDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;   
    align-content: center;
    justify-content: center;
    gap: 5%;
    align-items: center;
`

export const Card = styled.div`
    display:flex;
    flex-direction: column;
    background-color: var(--azul);
    width: 70%;
    height: 100%;
    border-radius: 35px;
    align-items:center;
    justify-content: space-around;
    gap: 10%;

    img{
        width: 300px;
    }
    
    @media (max-width: 800px) {
        gap: 5%;
        width: 80%;
        padding-top: 0;
        padding-bottom: 2rem;
        img{
            width: 250px;
            margin-top: 0px;
        }
    }

    @media (max-width: 400px) {
        gap: 5%;
        width: 100%;
        padding-top: 0;
        padding-bottom: 2rem;
        img{
            width: 200px;
            margin-top: 0px;
        }
    }
`

export const Titulo = styled.p`
    font-size: 1.8rem;
    text-align: center;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    width:fit-content;
    height:fit-content;
    
    @media (max-width: 1200px) {
        font-size: 1rem;   
    }
    @media (max-width: 800px) {
        font-size: 0.8rem;   
    }
    @media (max-width: 400px) {
        font-size: 0.7rem;   
    }
`

export const TitleHolder = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    gap: 5%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-width: 50%;
    height: 10%;
    border-radius: 30px;
    border: 4px dashed black;
    background-color: var(--background);
    padding: 10px;
`