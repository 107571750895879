import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { AlignJustify } from "lucide-react";
import Swal from "sweetalert2";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import { Buttons, Container, ContainerMenu, Menu, ButtonRender, Main, DivImagem, CartDiv } from "./style";
import Horizontal from "../../assets/images/frente.png";
import Vertical from "../../assets/images/back.png";
import { MenuAluno } from "../../Components/Responsivo/MenuAluno";
import Carterinha from "../../Components/Carterinha";
import Button from "../../Components/Button";
import RecuperarSenha from "../../Components/modals/RecuperarSenha";
import CarteirinhaHoriPdf from "../../Components/Pdf";
import PrimeiroAcesso from "../../Components/modals/PrimeiroAcesso";
import CarterinhaMobile from "../../Components/Responsivo/CarterinhaMobile";
import CarteirinhaVertiPdf from "../../Components/Pdf/CarteirinhaVertiPdf";
import api from "../../Services/api";
import QrCodeDadosPublicos from "../../Components/modals/QrCodeDadosPublicos/QrCodeDadosPublicos";
import Avisos from "../../Components/modals/Avisos";

const BREAKPOINT = 1100;

export default function HomeAluno() {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [userId, setUserId] = useState("");
  const [firstAccess, setFirstAccess] = useState(localStorage.getItem("Entrada"));
  const [isPasswordResetActive, setIsPasswordResetActive] = useState(false);
  const [isQrCodeVisible, setIsQrCodeVisible] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [firstNotice, setFirstNotice] = useState("");

  const componentRef = useRef(null);
  const componentVerticalRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("Entrada")) {
      const token2 = localStorage.getItem("token2");
      const decodedToken = atob(token2);
      const [id] = decodedToken.split(' ');
      setUserId(id);
      fetchNotices();
    }
  }, []);

  const fetchNotices = async () => {
    try {
      const response = await api.get(`/aluno/avisos`);
      const data = response.data;
      setFirstNotice(data.length > 0 && data.message !== "O(s) Aviso(s) não foram encontrados ou Já foram vizualizados." ? 'true' : 'false');
    } catch (error) {
      console.error("Failed to fetch notices:", error);
      Swal.fire("Erro", "Não foi possível exibir o aviso", "error");
    }
  };

  const handleDownload = async (ref, orientation = 'portrait') => {

    // if (!ref.current) {
    //   console.error("Elemento não encontrado:", ref);
    //   Swal.fire("Erro", "O elemento a ser capturado não está disponível", "error");
    //   return;
    // }
    setIsButtonDisabled(true);
    setIsDownloading(true);

    try {
      await Swal.fire({
        icon: "success",
        title: "Download em andamento, aguarde...",
        showConfirmButton: false,
        timer: 2200,
        timerProgressBar: true,
      });

      await api.get(`/log/entrar-aluno?id_aluno=${userId}`);
      setIsLoading(true);

      const element = ref.current;
      if (!element) {
        console.error("Elemento não encontrado:", ref);
        throw new Error("O elemento a ser capturado não está disponível");
      }

      await new Promise(resolve => setTimeout(resolve, 500));

      const canvas = await html2canvas(element, {
        logging: false,
        useCORS: true,
        scale: 2
      });

      const pdf = new jsPDF({
        orientation: orientation === 'portrait' ? 'landscape' : 'portrait',
        unit: "mm",
        format: "a4",
      });

      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10, orientation === 'portrait' ? 277 : 190, 0);
      pdf.save("carteirinha.pdf");

      setIsDownloading(false);
      setIsLoading(false);
      setIsMenuOpen(false);
    } catch (error) {
      console.error("Download failed:", error);
      Swal.fire("Erro", `Falha no download da carteirinha: ${error.message}`, "error");
    } finally {
      setIsButtonDisabled(false);
      setIsDownloading(false);
      setIsLoading(false);
    }
  };

  const renderDesktop = () => (
    <Container>
      <Main>
        <ButtonRender>
          <DivImagem>
            <img
              style={{ cursor: "pointer", maxWidth: "70vh", height: "26vh" }}
              src={isRotated ? Horizontal : Vertical}
              alt=""
              onClick={() => setIsRotated(!isRotated)}
            />
            <h1 style={{ fontSize: "25px", whiteSpace: "nowrap" }}>
              {isRotated ? "Modo impressão" : "Modo Smartphone"}
            </h1>
          </DivImagem>
        </ButtonRender>
        <CartDiv>
          {isRotated ? (
            <div >
              <CarterinhaMobile />
            </div>
          ) : (
            <div>
              <Carterinha color="red" />
            </div>
          )}
        </CartDiv>
        <Buttons>
          <Button
            width={'90%'}
            event={() => handleDownload((isRotated ? componentVerticalRef : componentRef), (isRotated ? 'landscape' : 'portrait'))}
            placeholder="Baixar Carteirinha"
            disabled={isButtonDisabled}
          />
          <Button
            width={'90%'}
            placeholder="Progressão Parcial"
            event={() => navigate('/progressao-parcial')}
          />
          <Button
            width={'90%'}
            event={() => setIsPasswordResetActive(true)}
            placeholder="Atualizar Senha"
          />
          <Button
            width={'90%'}
            event={() => setIsQrCodeVisible(true)}
            placeholder="QR CODE"
          />
          <Button
            width={'90%'}
            event={() => navigate("/")}
            placeholder="Sair"
          />
        </Buttons>
      </Main>
    </Container>
  );

  const renderMobile = () => (
    <ContainerMenu>
      <Menu>
        <AlignJustify
          color="black"
          size={40}
          onClick={() => setIsMenuOpen(true)}
        />
      </Menu>
      {windowSize.height > 600 ? <CarterinhaMobile /> : <Carterinha color="red" />}
    </ContainerMenu>
  );

  return (
    <>
      {isLoading && (
        <div className="divanimation">
          <motion.div
            className="load"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["0%", "0%", "50%", "50%", "0%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.2, 0.5, 0.8, 1],
              repeat: Infinity,
              repeatDelay: 1,
            }}
          />
        </div>
      )}

      <MenuAluno
        open={isMenuOpen} setOpen={setIsMenuOpen}
        componentVerticalRef={componentVerticalRef} componentRef={componentRef}
        isRotated={isRotated} senMenuDonwload={handleDownload}
      />
      <RecuperarSenha isActived={isPasswordResetActive} setIsActived={setIsPasswordResetActive} perfil={'aluno'} />
      <QrCodeDadosPublicos isShow={isQrCodeVisible} setIsShow={setIsQrCodeVisible} />
      <Avisos firstAviso={firstNotice} setFirstAviso={setFirstNotice} />
      <PrimeiroAcesso firstacess={firstAccess} setFirstAcess={setFirstAccess} />

      {/* Elementos invisíveis para capturar PDF */}
      {isDownloading ? (
        <>
          <div style={{ marginLeft: 3000, overflowX: "hidden" }}>
            <div ref={componentVerticalRef} style={{ width: 1200, height: 900, position: 'absolute' }}>
              <CarteirinhaVertiPdf />
            </div>
            <div ref={componentRef} style={{ width: 1800, position: "absolute" }}>
              <CarteirinhaHoriPdf />
            </div>
          </div>
        </>
      ) : windowSize.width > BREAKPOINT ? renderDesktop() : renderMobile()}
    </>
  );
}
