import React, { useState, useEffect } from "react";
// import { AreaVoz, Background, Card, CloseDiv, Container, Main } from './style';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

function ModalVoiceLogin({
    //     modalVoice,
    //     setModalVoice,
    //     rm,
    //     setRm,
    //     senha,
    //     setSenha
}) {
    //     const { transcript, listening, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();
    //     const [counter, setCounter] = useState(0);
    //     const [microRm, setMicroRm] = useState(true);
    //     const [microSenha, setMicroSenha] = useState(false);
    //     const [recordingStage, setRecordingStage] = useState(0); // 0: idle, 1: recording RM, 2: recording senha
    //     const [currentTranscriptRm, setCurrentTranscriptRm] = useState(""); // Store current transcript
    //     const [currentTranscriptSenha, setCurrentTranscriptSenha] = useState(""); // Store current transcript

    //     useEffect(() => {
    //         if (modalVoice) {
    //             if (!browserSupportsSpeechRecognition) {
    //                 const instructions = new SpeechSynthesisUtterance("O navegador não suporta o reconhecimento de voz.");
    //                 instructions.onend = () => {
    //                     if (recordingStage === 0) {
    //                         setMicroRm(false);
    //                         setMicroSenha(false);
    //                         setModalVoice(false);
    //                     }
    //                 };
    //                 window.speechSynthesis.speak(instructions);
    //                 SpeechRecognition.stopListening();
    //             } else {
    //                 if (recordingStage > 0) {
    //                     setRecordingStage(0);
    //                     setModalVoice(false);
    //                     limpar()
    //                 } else {
    //                     setCounter(0);
    //                     setMicroRm(true);
    //                     startInstructions();
    //                 }
    //             }
    //         }
    //     }, [modalVoice]);

    //     useEffect(() => {
    //         if (listening) {
    //             const newTimer = setInterval(() => setCounter(prevCounter => prevCounter + 1), 1000);
    //             const autoStopTimer = setTimeout(handleStop, 10000);
    //             return () => {
    //                 clearInterval(newTimer);
    //                 clearTimeout(autoStopTimer);
    //             };
    //         }
    //     }, [listening]);

    //     useEffect(() => {
    //         if (recordingStage === 1 && microRm) {
    //             setRm(transcript);
    //             setCurrentTranscriptRm(transcript);
    //         } else if (recordingStage === 2 && microSenha) {
    //             setSenha(transcript);
    //             setCurrentTranscriptSenha(transcript);
    //         }
    //     }, [transcript, microRm, microSenha, recordingStage, setRm, setSenha]);

    //     function startInstructions() {
    //         if ('speechSynthesis' in window) {
    //             setRm("")
    //             setSenha("")
    //             resetTranscript()
    //             const synth = window.speechSynthesis;
    //             const instructions = new SpeechSynthesisUtterance("Fale seu RM após o bip.");
    //             instructions.onend = () => {
    //                 if (recordingStage === 0) {
    //                     setMicroRm(true);
    //                     setMicroSenha(false);
    //                     setRecordingStage(1);
    //                     SpeechRecognition.startListening({ language: "pt-BR", continuous: true });
    //                 }
    //             };
    //             synth.speak(instructions);
    //             setTimeout(() => {
    //                 const beep = new SpeechSynthesisUtterance("PI");
    //                 synth.speak(beep);
    //             }, 1000);
    //         }
    //     };

    //     function handleStop() {
    //         SpeechRecognition.stopListening();
    //         console.log(`Current recording stage: ${recordingStage}`);
    //         console.log(`RM: ${rm}, Senha: ${senha}`);

    //         if (recordingStage === 1) {
    //             if ('speechSynthesis' in window) {
    //                 const synth = window.speechSynthesis;
    //                 const instructions2 = new SpeechSynthesisUtterance("Fale sua senha após o bip.");
    //                 instructions2.onend = () => {
    //                     setMicroRm(false);
    //                     setMicroSenha(true);
    //                     resetTranscript();
    //                     setRecordingStage(2);
    //                     SpeechRecognition.startListening({ language: "pt-BR", continuous: true });
    //                 };
    //                 synth.speak(instructions2);

    //                 setTimeout(() => {
    //                     const beep = new SpeechSynthesisUtterance("PI");
    //                     synth.speak(beep);
    //                 }, 1000);
    //             }
    //             setCounter(0);
    //         } else if (recordingStage === 2) {
    //             if ('speechSynthesis' in window) {
    //                 const synth = window.speechSynthesis;
    //                 SpeechRecognition.stopListening();
    //                 const repeatRM = new SpeechSynthesisUtterance(`Seu rm é: ${rm}?`);
    //                 repeatRM.onend = () => {
    //                     setRecordingStage(3);
    //                 }
    //                 synth.speak(repeatRM);
    //             }
    //             setCounter(0);
    //         } if (recordingStage === 3) {
    //             const finish = new SpeechSynthesisUtterance(`Sua senha é: ${senha}? Salvando...`);
    //             finish.onend = salvar;
    //             synth.speak(finish);
    //         }
    //     }

    //     function salvar() {
    //         setCounter(0);
    //         setRecordingStage(0);
    //         setMicroRm(false);
    //         setMicroSenha(false);
    //         setModalVoice(false);
    //     };

    //     function limpar() {
    //         window.speechSynthesis.cancel();
    //         SpeechRecognition.stopListening();
    //         setCounter(0);
    //         setRecordingStage(0);
    //         resetTranscript();
    //         setCurrentTranscriptRm("");
    //         setCurrentTranscriptSenha("");
    //         setRm('');
    //         setSenha('');
    //         setMicroRm(false);
    //         setMicroSenha(false);
    //     };

    //     function formatTime(seconds) {
    //         const minutes = Math.floor(seconds / 60);
    //         const secs = seconds % 60;
    //         return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    //     };

    //     if (!modalVoice) return null;

    //     if (!browserSupportsSpeechRecognition) {
    //         return (
    //             <Container>
    //                 <Background onClick={() => {
    //                     limpar();
    //                     setModalVoice(false);
    //                 }} />
    //                 <Card>
    //                     <Main>
    //                         <span>O navegador não suporta o reconhecimento de voz.</span>
    //                     </Main>
    //                 </Card>
    //             </Container>
    //         );
    //     }

    //     return (
    //         <Container>
    //             <Background onClick={() => {
    //                 limpar();
    //                 setModalVoice(false);
    //             }} />
    //             {(microRm || microSenha) && (
    //                 <Card>
    //                     <CloseDiv>
    //                         <h3>{microRm ? "micro rm ou e-mail" : "micro senha"}</h3>
    //                     </CloseDiv>
    //                     <AreaVoz>
    //                         <p>Microfone: {listening ? 'On' : 'Off'}</p>
    //                         {listening && <p>Tempo corrido: {formatTime(counter)}</p>}
    //                         <textarea
    //                             rows="5"
    //                             value={microRm ? currentTranscriptRm : currentTranscriptSenha}
    //                             style={{ width: '100%', resize: 'none' }}
    //                             readOnly
    //                         />
    //                     </AreaVoz>
    //                 </Card>
    //             )}
    //         </Container>
    // );
}

export default ModalVoiceLogin;
