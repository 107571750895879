import styled, { css, keyframes } from 'styled-components';
import { FaSearchengin } from "react-icons/fa"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  gap: 1rem;
  top: 8%;
  right: 0;
  width: 40%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 92%;
  // background-image: url(bgCont);
  background: rgb(21, 116, 208);
  background: linear-gradient(150deg, rgba(21, 116, 208, 1) 0%, rgba(27, 19, 118, 1) 100%);
  border-radius: 90px 90px 0px;
  border-top-right-radius: 0px;
  transition: 0.4s ease-in-out;
  box-shadow: 1px 8px 10px 7px rgba(0, 0, 0, 0.2);

  /* &:hover {
    transform: scale(1.02);
    box-shadow: 2px 4px 20px 8px rgba(0, 0, 0, 0.2);
  } */
   .update{
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-bottom: 10px;
    padding-right: 30px;
    color: #717171;
    font-size: 0.75rem;
    z-index: 80;
   }
`

export const Card = styled.div`
  position: fixed;
  top: 8%;
  left: 0;
  width: 52%;
  height: 85%;
  background-color: transparent;
  border-radius: 0px 0px 90px;
  border-top-right-radius: 90px;
  margin-bottom: -2.5%;
  transition: 0.4s ease-in-out;
`

export const Info = styled.div`
  display: block;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5%;
  border: 1px solid black;
  border-radius: 20px;
  padding: 5px 20px;
  background-color: var(--background);
  color: var(--azul);
  font-family: Dancing Script;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--background);
  border-radius: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  width: 50%;
  min-height: 25rem;
  padding: 40px;
  /* box-shadow: 2px 4px 10px 8px rgba(0, 0, 0, 0.2); */
  transition: 0.4s ease-in-out;
  gap: 2%;
  
  &:hover {
    transform: scale(1.02);
    box-shadow: 2px 4px 20px 8px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1050px){
    width: 20rem;
    border-radius: 15px;
  }
  @media (max-width: 450px){
    width: 15rem;
  }
`

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--azul);
  width: 100%;
  font-size: 2.5em;
  background-color: transparent;
  border-radius: 20px;
  font-family: Dancing Script;
  padding-bottom: 1%;

  .ComoAcessar{
    display: flex;
    cursor: pointer;
    font-size: 0.9rem;
    gap: 3px;
    margin-bottom: 10px;
    color: black;
    text-transform: capitalize;
    text-decoration: underline;

    &:hover{
      color: #0000CC;
    }
  }

  @media (max-height: 780px){
    padding-top: 2rem;
    font-size: 2.5em;
  } 
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.5em;
  /* padding-top: 2em; */
  outline: none;
  width: 100%;
  color: var(--azul);
  height:fit-content;
  background-color: transparent;

  @media (min-width: 2500px) {
    gap: 4em;
  }
  @media (max-width: 1450px) {
    gap: 1.5em;
  }
`

export const InputIcon = styled.svg`
  height: 2em;
  width: 2em;
  fill: #012340;
`

export const InputDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  width: 100%;

  @media (max-width: 1050px) {
    gap: 1em;
  }

  @media (max-width: 650px) {
    width: 10rem;
  }
`

export const Forget = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: relative;
  width: fit-content;
  height: fit-content;
  transition: transform 0.5s;
  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }
`

export const Icon = styled(FaSearchengin)`
  position: relative;
  place-items: center;
  width: 10%;
  color: var(--azul);
`

export const Btn = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 2.5em;
`

export const Button1 = styled.button`
  padding: 1em;
  padding-left: 1.1em;
  padding-right: 1.1em;
  border-radius: 5px;
  margin-right: 0.5em;
  border: none;
  outline: none;
  transition: 0.4s ease-in-out;
  background-color: #d5e5f2;
  color: black;
  font-size: 1em;

  &:hover {
    background-color: black;
    color: white;
  }
`

export const Button2 = styled.button`
  padding: 1em;
  padding-left: 2.3em;
  padding-right: 2.3em;
  border-radius: 5px;
  border: 1px solid #012340;
  transition: 0.4s ease-in-out;
  background-color: #012340;
  color: #d5e5f2;
  font-size: 1em;

  &:hover {
    background-color: #012340;
    box-shadow: 0px 15px 20px #012340;
    color: #d5e5f2;
    transform: translateY(-7px);
  }
`

export const Button3 = styled.button`
  margin-bottom: 3em;
  padding: 0.8em;
  border-radius: 5px;
  border: 1px solid #012340;
  outline: none;
  transition: 0.4s ease-in-out;
  background-color: #d5e5f2;
  color: #012340;
  font-size: 1em;

  &:hover {
    background-color: #ff735c;
    box-shadow: 0px 15px 20px #ff735c;
    color: #fff;
    transform: translateY(-7px);
  }
`

export const Input = styled.input`
  font-size: 100%;
  padding: 0.8em;
  color: #d5e5f2;
  outline: none;
  border: 2px solid #012340;
  border-radius: 20px;
  width: 100%;
  background-color: #d5e5f2;
  // position: absolute;
  // left: 50%;
  // transform: translateX(-50%);
`

export const Sim = styled.div`
  margin-top: 1.5rem;
  margin-bottom: -2rem;
`

export const ContainerResponsive = styled.div`
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: scroll;
  background: rgb(21, 116, 208);
  background: linear-gradient(150deg, rgba(21, 116, 208, 1) 0%, rgba(27, 19, 118, 1) 100%);
  padding-top: 5%;
  /* Esconder a scrollbar no navegador Chrome, Safari e Edge */
  ::-webkit-scrollbar {
    display: none;
  }
  /* Esconder a scrollbar no Firefox */
  scrollbar-width: none;
  /* Prevenir problemas de layout ao esconder a scrollbar */
  -ms-overflow-style: none;  /* IE 11 */
  
  @media (max-width: 400px) {
    margin-top: 5%;
  }
  .update{
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    padding-bottom: 10px;
    padding-left: 5px;
    color: #717171;
    font-size: 0.7rem;
    z-index: 80;
   }
`

export const FormResponsive = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22rem;
  height: fit-content;
  margin-top: 10%;
  margin-bottom: 10%;
  gap: 1rem;
  border-radius: 10px;
  background-color: var(--background);
  box-shadow: 2px 4px 10px 8px rgba(0, 0, 0, 0.2);
  transition: 0.4s ease-in-out;

  .ComoAcessar{
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 10px;
    cursor: pointer;
    color: var(--azul);

    &:hover{
      text-decoration: underline;
      color: #0000CC;
    }
  }

  @media (max-width: 1250px) {
    margin-top: 5%;
  }
  @media (max-width: 450px) {
    width: 18rem;
    gap: 0rem;
  }
  @media (max-height: 450px) {
    width: 25rem;
    gap: 0rem;
  }
`

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const SelectedPerfils = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 2rem;
  font-size: 1em;
  background-color: white;
  border-radius: 100px;
  text-transform: capitalize;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.5s;
  position: relative;

  p {
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    background-color: white;
    border: 1px solid;
    
    &:hover {
      transition: all 0.5ms ease;
      transform: scale(1.05);
    }
  }
  .aluno {
    border-radius: 100px 0 0 100px;
  }
  .serv {
    border-radius: 0 100px 100px 0;
  }

  ${({ $aluno, $servidor }) => ($aluno || $servidor) && css`
    animation: ${slideUp} 0.5s ease-out forwards;
  `}

  ${({ $aluno }) =>
    $aluno &&
    `
    .aluno {
      background-color: beige;
      font-size: 0.8rem;
    }
  `}

  ${({ $servidor }) =>
    $servidor &&
    `
    .serv {
      background-color: beige;
      font-size: 0.8rem;
    }
  `}
`;

export const HeadingResponsive = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--azul);
  width: 100%;
  font-size: 2em;
  background-color: transparent;
  border-radius: 20px;
  font-family: Dancing Script;
  padding-top: 2rem;

  .ComoAcessar{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    font-size: 0.65rem;
    color: black;
    text-transform: capitalize;
    text-decoration: underline;

    &:hover{
      color: #0000CC;
    }
  }
`

export const FieldResponsive = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
  gap: 2em;
  padding: 2em 4em 1em 4em;
  outline: none;
  color: var(--azul);
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  
  @media (max-width: 900px) {
    width: 55%;
  }
  
  @media (max-width: 400px) {
    width: 50%;
  }
`
export const ButtonsResponsive = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem;
  height: 10%;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`
export const ButtonsResponsive2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const ErroMensage = styled.small`
  font-family: Dancing Script;
  text-align: center;
  color: red;
  /* margin: 0.5rem; */
`
export const ButtonVoice = styled.button`
  margin-left: 10px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 10px;
  color: var(--azul);
`