import React from 'react'
import { Header, Img, Logo, LogoutIcon } from './style'
import LogoFiec from "../../assets/images/fiec-logo.png"
import { useNavigate } from 'react-router-dom'
import { Nav, Bars, NavResponsive, NavMenu, NavLink, Logoperfil } from "././././style.js";
import api from '../../Services/api'
import { Authenticator, AuthenticatorAdmin, AuthenticatorCoordenador, AuthenticatorProfessor } from '../../auth'

export default function Navbar(props) {

  const Navigate = useNavigate()
  const token = localStorage.getItem("token")

  function LogVerify() {
    api.get('/autenticar-token', token)
      .then((res) => {
        if (res.data.perfil === "aluno") {
          if (window.location.href.includes('/aluno')) {
            // console.log('haha1');
            window.location.reload()
          }
          Navigate("/aluno")
        } else if (res.data.perfil === "usuario" || res.data.perfil === "secretaria") {
          if (window.location.href.includes('/secretaria')) {
            // console.log('haha2');
            window.location.reload()
          }
          Navigate("/secretaria")
        } else if (res.data.perfil === "coordenador") {
          if (window.location.href.includes('/coordenador')) {
            // console.log('haha3');
            window.location.reload()
          }
          Navigate("/coordenador")
        } else if (res.data.perfil === "professor") {
          if (window.location.href.includes('/professor')) {
            // console.log('haha4');
            window.location.reload()
          }
          Navigate("/professor")
        } else {
          // console.log('haha 222');
          Navigate("/")
        }
      }).catch((err) => {
        if (window.location.href.includes('/  ')) {
          window.location.reload()
        }
        // console.log("Erro: ", err)
        Navigate("/")
      })
  }

  function Logout() {
    if (
      (AuthenticatorAdmin() == true) ||
      (Authenticator() == true) ||
      (AuthenticatorCoordenador() == true) ||
      (AuthenticatorProfessor() == true)
    ) {
      return (
        <LogoutIcon
          onClick={() => {
            localStorage.clear();
            Navigate("/");
          }}
          title="Sair"
        />
      )
    }
  }

  return (
    <Header>
      <Logo>
        <Img onClick={() => LogVerify()} src={LogoFiec} alt='LOGO DA FIEC' />
      </Logo>
      <Logout />
      <NavMenu>
        <NavLink onClick={() => window.location.href = "https://alunos.tawk.help"} target={"_blank"}>
          Ajuda ?
        </NavLink>
      </NavMenu>
    </Header>
  )
}
