import React, { useCallback, useEffect, useState } from "react";
import {
  FirstCard, Form, Field, Heading, Arroba
} from "./style";
import Input from "../../Input";
import api from "../../../Services/api";
import Button from "../../Button";
import { Alert } from "../../../utils/Alert";
import { SendImageApi } from "../../../Services/sendImage.";
import Icone from "../../../utils/Icone";
import InputSelect from "../../Select";


export default function CadastroCurso({ Show, setShow }) {
  if (!Show) return null;
  const [nomeCurso, setNomeCurso] = useState("")
  const [convenio, setConvenio] = useState("")
  const [cor_convenio, setCor_convenio] = useState("")
  const [duracao_mes, setDuracao_mes] = useState("")
  const [Estado, setEstado] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const optionsColorConvenio = [
    { label: " - ", value: '' },
    { label: "Amarelo", value: 'yellow' }, { label: "Azul", value: 'blue' },           // #FFFF00  // #0000FF        
    { label: "Branco", value: 'white' }, { label: "Cinza", value: 'grey' },            // #FFFFFF  // #808080
    { label: "Laranja", value: 'orange' }, { label: "Marrom", value: '#6d4b3c' },    // #FFA500
    { label: "Preto", value: 'black' }, { label: "Rosa", value: 'pink' },              // #000000 // #FFC0CB
    { label: "Roxo", value: 'purple' }, { label: "Verde", value: 'green' },            // #800080 // #008000
    { label: "Verde claro", value: '#6edd6e' }, { label: "Vermelho", value: 'red' }  // #FF0000
  ];

  useEffect(() => {
    if (!Show) return null;
    setEstado(Show);
  }, [Show]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!nomeCurso) {
      Alert('Erro2', 'Por favor, preencha o nome do curso.');
      setIsSubmitting(false);
      return;
    }
    if (!convenio) {
      Alert('Erro2', 'Por favor, preencha o convênio.');
      setIsSubmitting(false);
      return;
    }
    if (!cor_convenio) {
      Alert('Erro2', 'Por favor, preencha a cor do curso.');
      setIsSubmitting(false);
      return;
    }
    if (!duracao_mes) {
      Alert('Erro2', 'Por favor, preencha a duração do curso em meses.');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await api.post("/curso/cadastrar", {
        data: { nome: nomeCurso, convenio, cor_convenio, duracao_mes }
      });

      Alert('Sucesso', response.data.mensagem || 'Curso cadastrado com sucesso!');
      setShow(false);
    } catch (error) {
      console.error("Erro ao cadastrar curso:", error);
      Alert('Erro', error.response?.data?.erro || 'Ocorreu um erro ao cadastrar o curso. Por favor, tente novamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FirstCard Estado={Estado}>
      <Arroba onClick={() => { setEstado(false), setTimeout(() => { setShow(false); }, 300); }}>
        <Icone name="X" color="#F0F0F0" size={46} />
      </Arroba>
      <Form onSubmit={handleSubmit}>
        <Heading>Cadastrar Curso</Heading>
        <Field $color={cor_convenio}>
          <div>
            <Input
              name="nomeCurso"
              width="12rem"
              color="#FFFFFF"
              type="text"
              placeholder="Nome do Curso"
              required={true}
              value={nomeCurso}
              event={(e) => setNomeCurso(e.target.value)}
            />
            <br />
            <br />
            <Input
              name="convenio"
              width="12rem"
              color="#FFFFFF"
              type="text"
              placeholder="Convênio"
              required={true}
              value={convenio}
              event={(e) => setConvenio(e.target.value)}
            />
            <br />
            <br />
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', gap: 15 }}>
              <InputSelect
                Style2={{ color: "white", border: "1px solid white", borderRadius: "50px" }}
                Style3={{ color: "white" }}
                Label={"Cor do Convenio"}
                Options={optionsColorConvenio}
                Default={cor_convenio}
                OnChange={(e) => { setCor_convenio(e.target.value) }}
              />
              <span className="color" />
            </div>
            {/* <Input
              name="cor_convenio"
              width="10rem"
              color="#FFFFFF"
              type="text"
              placeholder="Cor convênio"
              required={true}
              value={cor_convenio}
              event={(e) => setCor_convenio(e.target.value)}
            /> */}
            <br />
            <br />
            <div>
              <Input
                name="duracao_mes"
                width="10rem"
                color="#FFFFFF"
                type="text"
                placeholder="Duração do curso"
                required={true}
                value={duracao_mes}
                event={(e) => setDuracao_mes(e.target.value)}
                inputMode="numeric"
                apenasNumeros={true}
                maxLength={2}
              />
              <small style={{ color: '#afafaf' }}>Duração do curso em meses</small><br />
              <small style={{ color: '#afafaf' }}>Exemplo: 18.</small>
            </div>
          </div>
        </Field>
        <div style={{ display: 'flex', paddingTop: '2rem' }}>
          <Button
            width="10rem"
            placeholder="Enviar"
            type="submit"
            disabled={isSubmitting}
          />
        </div>
      </Form>
    </FirstCard>
  );
}
