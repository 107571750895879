import "./style.css"
import React from "react"
import {BiSearchAlt} from "react-icons/bi"

export default function Pesquisar(props) {
  return (
    <div className="form" style={{width: props.width,}}>
      <BiSearchAlt className="bife"></BiSearchAlt>
      <input
        className="input"
        placeholder={props.placeholder}
        required=""
        type="text"
        name={props.name}
        onInput={props.onInput}
        pattern={props.pattern}
        inputMode={props.inputMode}
        maxLength={props.maxLength}
        onChange={props.event}
        style={{
          color: props.color,
        }}
      />
      <span for={props.span} style={{color: props.spanColor}} className="input-border">{props.span}</span>
    </div>
  )
}
