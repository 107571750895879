import React, { useEffect, useState } from 'react'
import { Page, Title, DivMain, Table, Theader, Th, Tbody, Trbody, Td, Buttons, Menus, ContainerTable, TdDisciplina, Description, DivText } from './styles'
import { useNavigate } from 'react-router-dom';
import Button from '../../../Components/Button/index.jsx';
import api from '../../../Services/api.js';
import { format } from "date-fns"
import Swal from 'sweetalert2';
import CardAdicionar from '../../../Components/ProgressaoParcial/CardAdicionar';
import CardEditarProgressao from '../../../Components/ProgressaoParcial/CardEditar'
import CardGerarResultadoProgressao from '../../../Components/ProgressaoParcial/CardGerarResultProgre/index.jsx';
import { Alert } from '../../../utils/Alert.js';
import { MdAutoDelete, MdDelete } from "react-icons/md";
import { IoMdListBox } from 'react-icons/io';
import { FiEdit } from "react-icons/fi";
import { FaFilePdf, FaRegFile } from "react-icons/fa6";
import { PiListChecksFill } from "react-icons/pi";
import { LuEye } from "react-icons/lu";
import CardVerPdfProgressao from '../../../Components/ProgressaoParcial/CardVerPdfProgressao/index.jsx';
import CardListarAlunosPP from '../../../Components/ProgressaoParcial/CardListarAlunosPP/index.jsx';
import Loading from '../../../utils/Loading/Loading.jsx';
import { Description2 } from '../Coordenador/styles.js';
import { FaFileUpload, FaFileWord } from 'react-icons/fa';
import InsirirVerDocExemplar from '../../../Components/ProgressaoParcial/InsirirDocExemplar/index.jsx';

export const ProgressaoParcialProfessor = () => {
  const Navigate = useNavigate()
  const [range, setRange] = useState(window.innerWidth)
  const [progressaoEdit, setProgressaoEdit] = useState([]);
  const [adicionar, setAdicionar] = useState(false);
  const [resultado, setResultado] = useState(false);
  const [Editar, setEditar] = useState(false);
  const [deletandoId, setDeletandoId] = useState(null);
  const [progressoes, setProgressoes] = useState([]);
  const [pdfResultado, setPdfResultado] = useState(false);
  const [pdfProgressao, setPdfProgressao] = useState(false);
  const [MostrarAlunos, setMostrarAlunos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addExemplar, setAddExemplar] = useState(false);

  const pc = range > '1000px'

  useEffect(() => {
    setLoading(true);
    ListarProgressoes();
  }, []);

  const date = new Date();
  const dataStr = date.toLocaleString('pt-BR').split(' ')[0];
  const [dia, mes, ano] = dataStr.split('/');

  async function ListarProgressoes() {
    try {
      const res = await api.get("/professor/listar-progressoes");
      setProgressoes(res.data);
    } catch (err) {
      console.clear();
      // console.log("Erro: ", err);
      // console.log(err.response?.data?.erro);
      // Swal.fire({
      //   title: "Erro",
      //   text: err.response?.data?.erro || "Erro interno de servidor",
      //   icon: "error",
      //   iconColor: "#ff0000",
      //   confirmButtonColor: "var(--azul)",
      //   confirmButtonText: "Confirmar",
      // });
    } finally {
      setLoading(false);
    }
  }

  function DeletarProgressoes(id, pdf, andamento, terminou) {
    if (andamento) {
      return Alert("Erro3", 'Está progressão já começou. Nâo é possível deleta-lá!')
    } else if (terminou) {
      return Alert("Erro3", 'Está progressão já terminou. Nâo é possível deleta-lá')
    } else {
      Alert("Alert", "Deseja deletar essa progressão?")
        .then(async (result) => {
          if (result.isConfirmed) {
            setDeletandoId(id);
            try {
              await api.delete(`/professor/deletar-progressao`, {
                params: { id, pdf }
              })
                .then(async (response) => {
                  await Alert("Sucesso", `${response.data.mensagem ? response.data.mensagem : 'Progressão deletada com sucesso'}`)
                    .then(() => {
                      setDeletandoId(null);
                      if (progressoes.length == 0) {
                        return window.location.reload();
                      }
                      ListarProgressoes();
                    });
                }).catch((erro) => {
                  Alert("Erro3", `${erro.response.data.erro ? erro.response.data.erro : 'Erro ao deletar a progressão'}`)
                  setDeletandoId(null);
                })
            } catch (err) {
              console.log("Erro: ", err);
              setDeletandoId(null);
            }
          }
        });
    }
  }

  return (
    <Page >
      <DivMain>
        <Menus>
          <Title>Turmas Progressão Parcial</Title>
          <Buttons>
            <Button
              event={() => {
                Navigate("/Professor")
              }}
              width={pc ? "8rem" : ""}
              margintop={pc ? "1%" : "1rem"}
              placeholder="Voltar"
              backgroundcolor="#1a2e35"
              borderRadius="30px 30px 0px 0px"
            />
            <Button
              event={() => setAdicionar(true)}
              width={pc ? "10rem" : ""}
              margintop={pc ? "1%" : "1rem"}
              backgroundcolor="#1a2e35"
              placeholder="Adicionar"
              borderRadius="30px 30px 0px 0px"
            />
          </Buttons>
          {progressoes.length ? (
            <>
              <Description2 onClick={() => setAddExemplar(true)}>
                <div>
                  <span>Visualizar o Exemplo do Dococumento da Progressão</span>
                </div>
                <FaFileWord size={'3rem'} color='#fff' />
              </Description2>
              <Description>
                <p><span className='andamento' />Em Andamento</p>
                <p><span className='terminou' />Encerradas</p>
              </Description>
            </>
          ) : null}
        </Menus>
        {loading ? (
          <ContainerTable $loading={loading}>
            <Loading />
          </ContainerTable>
        ) : (
          <ContainerTable>
            {progressoes.length ?
              (
                <Table>
                  <Theader>
                    <tr>
                      <Th>Professor</Th>
                      <Th>Curso</Th>
                      <Th>Turma</Th>
                      <Th>Disciplina</Th>
                      <Th>Início</Th>
                      <Th>Término</Th>
                      <Th>Avaliar</Th>
                      <Th>Ver Alunos</Th>
                      <Th>PDF <p>Progressão</p></Th>
                      <Th>Editar</Th>
                      <Th>Deletar</Th>
                    </tr>
                  </Theader>
                  <Tbody>
                    {progressoes?.map((d, i) => {
                      const dataAtual = new Date();
                      const dataInicio = new Date(d.data_inicio);
                      const dataFim = new Date(d.data_fim);
                      const andamento = dataAtual >= dataInicio && dataAtual <= dataFim;
                      const terminou = dataAtual > dataFim;
                      // Adiciona 15 dias à data de fim
                      const dataFimMais15Dias = new Date(dataFim);
                      dataFimMais15Dias.setDate(dataFimMais15Dias.getDate() + 15);
                      // Verifica se a data atual é maior que a data de fim + 15 dias
                      const MaisDe15Dias = dataAtual > dataFimMais15Dias;
                      const turma = d.turma_rh.split(',')[1].split('-').slice(1).join(' - ');
                      const curso = d.curso_rh.split(',')[1].replace(/(TÉCNICO EM|TECNÓLOGO EM)/g, '').trim();

                      return (
                        <Trbody key={i}>
                          <Td>
                            {terminou ? <p><span className='terminou' />{typeof d?.professor === 'string' ? d.professor.split(',')[1] : 'Professor inválido'}</p>
                              : andamento ? <p><span className='andamento' />{typeof d?.professor === 'string' ? d.professor.split(',')[1] : 'Professor inválido'}</p>
                                : <p>{typeof d?.professor === 'string' ? d.professor.split(',')[1] : 'Professor inválido'}</p>}
                          </Td>
                          <Td>
                            {typeof d?.curso_rh === 'string' ? curso : 'Curso inválido'}
                          </Td>
                          <Td className='turma'>
                            {typeof d?.turma_rh === 'string' ? turma : 'Turma inválida'}
                          </Td>
                          <TdDisciplina>
                            {typeof d?.disciplina_rh === 'string' ? d.disciplina_rh.split(',')[1] : 'Disciplina inválida'}
                          </TdDisciplina>
                          <Td>
                            {d?.data_inicio ? format(dataInicio, "dd/MM/yyyy") : 'Data inválida'}
                          </Td>
                          <Td className='icon'>
                            {d?.data_fim ? format(dataFim, "dd/MM/yyyy") : 'Data inválida'}
                          </Td>
                          <Td className='icon'>
                            <PiListChecksFill
                              onClick={() => {
                                if (terminou) {
                                  setResultado(!resultado);
                                  setProgressaoEdit(d);
                                } else {
                                  Alert('Alert2', `Avaliar somente após o Encerramento: ${format(dataFim, "dd/MM/yyyy")}`)
                                }
                              }}
                              size={'2rem'}
                              cursor={'pointer'}
                              title={terminou ? 'Inserir Resultado' : `Após o Encerramento: ${format(dataFim, "dd/MM/yyyy")}`}
                              color={terminou ? '#000' : '#535353'}
                            />
                          </Td>
                          <Td className='icon'>
                            <LuEye
                              onClick={() => {
                                setMostrarAlunos(true);
                                setProgressaoEdit(d);
                              }}
                              size={'2rem'}
                              cursor={'pointer'}
                              title='Ver Alunos'
                            />
                          </Td>
                          <Td className='icon'>
                            <FaFilePdf
                              onClick={() => {
                                if (!d?.pdf) { return Alert('Alert2', "Não há pdf disponível") }
                                else { return setPdfProgressao(true), setProgressaoEdit(d) }
                              }}
                              size={'1.8rem'}
                              cursor={'pointer'}
                              title='Ver PDF'
                              color={d?.pdf ? '#de000e' : '#535353'}
                            />
                          </Td>
                          <Td className='icon'>
                            <FiEdit
                              onClick={() => {
                                setEditar(true), setProgressaoEdit(d)
                                // if (MaisDe15Dias) { return Alert('Alert3', `Esta progressão terminou há mais de 15 dias. Não é possível editá-la!`); }
                                // else { return setEditar(true), setProgressaoEdit(d) }
                              }}
                              size={'2rem'}
                              cursor={'pointer'}
                              // cursor={MaisDe15Dias ? 'not-allowed' : 'pointer'}
                              title='Editar'
                            />
                          </Td>
                          <Td className='icon'>
                            {deletandoId !== d?.id ? (
                              <MdDelete
                                onClick={() => DeletarProgressoes(d?.id, d?.pdf, andamento, terminou)}
                                size={'2rem'}
                                cursor={andamento || terminou ? 'not-allowed' : 'pointer'}
                                title='Deletar'
                              />
                            ) : (
                              <MdAutoDelete
                                size={'2rem'}
                                cursor={'pointer'}
                                title='Deletando'
                              />
                            )}
                          </Td>
                        </Trbody>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <>
                  <Table>
                    <Theader>
                      <tr>
                        <Th>Professor</Th>
                        <Th>Curso</Th>
                        <Th>Turma</Th>
                        <Th>Disciplina</Th>
                        <Th>Início</Th>
                        <Th>Término</Th>
                        <Th>Avaliar</Th>
                        <Th>Ver Alunos</Th>
                        <Th>PDF <p>Progressão</p></Th>
                        <Th>Editar</Th>
                        <Th>Deletar</Th>
                      </tr>
                    </Theader>
                  </Table>
                  <DivText>Nenhuma Progressão Adicionada</DivText>
                </>
              )}
          </ContainerTable>
        )}
      </DivMain>
      <CardGerarResultadoProgressao show={resultado} setShow={setResultado} progressaoEdit={progressaoEdit} />
      <CardAdicionar Show={adicionar} setShow={setAdicionar} />
      <CardEditarProgressao show={Editar} setShow={setEditar} progressaoEdit={progressaoEdit} />
      <CardVerPdfProgressao Show={pdfProgressao} setShow={setPdfProgressao} progressaoEdit={progressaoEdit} />
      <CardListarAlunosPP MostrarAlunos={MostrarAlunos} setMostrarAlunos={setMostrarAlunos} progressaoEdit={progressaoEdit} />
      <InsirirVerDocExemplar show={addExemplar} setShow={setAddExemplar} perfil={'prof'} />
    </Page>
  )
}