import React, { useEffect, useState } from "react"
import { Buttons, Card,  Container, Main, Header, Title, Content } from "./style"
import Button from "../../Button"
import { Alert } from "../../../utils/Alert"
import api from "../../../Services/api"

export default function Avisos({ firstAviso, setFirstAviso }) {
    const token2 = localStorage.getItem("token2")
    const [Aviso, setAviso] = useState([])
    const [AvisosVizualizados, setAvisosVizualizados] = useState([])


    useEffect(() => {
        MostarAvisos()
    }, [])
    function MostarAvisos() {
        const descript = atob(token2)
        const idsArray = descript.split(' ');
        const id = idsArray[0]
        api.get(`/aluno/avisos`)
            .then((response) => {
                try {
                    const data = response.data
                    if (data.length <= 0) {
                        setFirstAviso('false')
                    } else if (response.data.mansagem == "O(s) Aviso(s) não foram encontrados ou Já foram vizualizados.") {
                        setFirstAviso('false')
                    }
                    setAviso(data)
                } catch (erro) {
                    console.log(erro)
                    Alert("Erro", "Não foi possível exibir o aviso")
                }
            })
    }

    const visualizado = () => {
        const descript = atob(token2)
        const idsArray = descript.split(' ');
        const id = idsArray[0]
        const Ids = (Aviso[0].id + ' ' + id)
        api.post(`/aluno/inserir-aviso-visualizado?ids=${Ids}`)
            .then((res) => {
                try {
                    // console.log(res.data.mensagem);
                    MostarAvisos()
                } catch (erro) {
                    console.log(erro)
                    Alert("Erro", 'Falha na visualização do Aviso.')
                }
            })
    }
    /* const avisosVizualizados = () => {
        api.get(`/aluno/avisos-vizualizados?id_aluno=${IdUser}`)
            .then(async (response) => {
                try {
                    if (response.data.mensagem ==  "Nenhum aviso foi visualizado!") {
                        Alert('Erro', response.data.mansagem)
                    }
                    const data = await response.data
                    setAvisosVizualizados(data)
                } catch (erro) {
                    console.log(erro)
                    Alert("Erro", "Não foi possível exibir o aviso já visto")
                }
            })
    } */

    if (firstAviso == 'true') {
        let index = 0;
        while (index < Aviso.length) {

            return (
                <Container>
                    <Card>
                        <Header>
                            <Title>De olho nas novidades</Title>
                        </Header>
                        <Main>
                            <Content>{Aviso[index].descricao}</Content>
                        </Main>
                        <Buttons>
                            <Button
                                id={"button"}
                                width="20vh"
                                height="100%"
                                event={() => { visualizado() }}
                                placeholder="OK"
                                padding="10px"
                            />
                        </Buttons>
                    </Card>
                </Container >
            )
        }
    } else {
        return null
    }
}
