import React, { useState } from "react"
import { Buttons, Card, Checkb, Contanier, Forms, Header, LabelTermos, Title } from "./style"
import Input from "../../Input"
import Button from "../../Button"
import { Alert } from "../../../utils/Alert"
import Icone from "../../../utils/Icone"
import api from "../../../Services/api"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

export default function PrimeiroAcesso({ firstacess, setFirstAcess }) {
  const Navigate = useNavigate()
  const [senha, setSenha] = useState("")
  const [senhaConfirmar, setSenhaConfirmar] = useState("")
  const [Termos, setTermos] = useState(false)
  const token = localStorage.getItem("token")

  async function submitEvent() {
    try {
      if (senha !== "" && senha == senhaConfirmar && Termos == true) {
        const data = {
          senha: senha,
        }
        const headers = {
          Authorization: `Bearer ${token}`,
        }
        const config = {
          headers: headers,
        }
        const response = await api.put("aluno/definir-senha", { data }, config)
        // console.log(response)

        if (response.status == 200) {
          setFirstAcess("false")
          Alert("Sucesso", "Senha alterada, necessário novo login !")
          localStorage.clear()
          return Navigate("/")
        } else {
          Alert("Erro", "Não foi possível alterar a senha")
        }
      } else if (senha === "") {
        Alert("Erro", "Digite uma nova senha !")
      } else if (senhaConfirmar !== senha) {
        Alert("Erro", "As senhas não são iguais !")
      } else {
        Alert("Erro", "Concorde com os termos")
      }
    } catch (erro) {
      console.log(erro)
      Alert("Erro", "Não foi possível alterar a senha")
    }
  }
  if (firstacess == "true") {
    return (
      <Contanier>
        <Card>
          <Header>
            <Title>Definir Senha</Title>
          </Header>
          <Forms>
            <Input
              width="30vh"
              color="black"
              type={"password"}
              placeholdercolor="black"
              placeholder="Sua nova senha"
              required={"true"}
              event={(e) => {
                setSenha(e.target.value)
              }}
            />
            <Input
              width="30vh"
              color="black"
              type={"password"}
              placeholdercolor="black"
              placeholder="Confirmar senha"
              required={"true"}
              event={(e) => {
                setSenhaConfirmar(e.target.value)
              }}
            />
            <LabelTermos>
              <Input
                type={"checkbox"}
                height="20px"
                width="20px"
                marginbottom="20px"
                event={() => {
                  // aloha
                  setTermos(!Termos)
                }}
              />
              <p>
                Concordo com os{" "}
                <a href="https://www.fiec.com.br/site/politica-de-privacidade-site" target="_blank">
                  termos
                </a>
              </p>
            </LabelTermos>
          </Forms>
          <Button
            id={"button"}
            width="20vh"
            height="6vh"
            event={() => {
              submitEvent()
            }}
            placeholder="Enviar"
            marginBottom="40px"
          />
        </Card>
      </Contanier>
    )
  } else if (firstacess == "false") {
    return null
  }
}
