import React from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import Login from "./Pages/Login"
import HomeAluno from "./Pages/HomeAluno"
import HomeSecretaria from "./Pages/HomeSecretaria"
import Navbar from "./Components/Navbar"
import Globalstyles from "./globalstyles"
import { Authenticator, AuthenticatorAdmin, AuthenticatorCoordenador, AuthenticatorProfessor } from "./auth"
import NaoAutorizado from "./Pages/NaoAutorizado"
import CadastroAluno from "./Pages/CadastroAluno"
import DesativarAluno from "./Pages/DesativarAluno"
import GerarCarteirinhas from "./Pages/GerarCarteirinhas"
import VisualizarAlunos from "./Pages/VisualizarAlunos"
import VerCarterinhas from "./Pages/VerCarterinhas"
import VisualizarUsuarios from "./Pages/VisualizarUsuarios"
import { useEffect, useState } from "react"
import AlunoSemFoto from "./Pages/AlunoSemFoto"
import VerCarterinha from "./Pages/VerCarterinha"
import DadosPublicos from "./Pages/DadosPublicos/index.jsx"
import CarteirinhaPdfS from "./Components/Pdf/carteirinhaSecreHori"
import CarteirinhaVertiPdf from "./Components/Pdf/CarteirinhaVertiPdf"
import Pdf from "./Components/Pdf"
import ProfessorHome from "./Pages/HomeProfessor/index.jsx"
import CoordenadorHome from "./Pages/HomeCoordenador/index.jsx"
import { ProgressaoParcialCoordenador } from "./Pages/ProgressaoParcial/Coordenador/index.jsx"
import { ProgressaoParcialProfessor } from "./Pages/ProgressaoParcial/Professor/index.jsx"
import { ProgressaoParcialAluno } from "./Pages/ProgressaoParcial/Aluno/index.jsx"

function App() {
  function AuthRoute({ children }) {
    return Authenticator() ? children : <NaoAutorizado />
  }

  function AuthAdmRoute({ children }) {
    return AuthenticatorAdmin() ? children : <NaoAutorizado />
  }

  function AuthProfessorRoute({ children }) {
    return AuthenticatorProfessor() ? children : <NaoAutorizado />
  }

  function AuthCoordenadorRoute({ children }) {
    return AuthenticatorCoordenador() ? children : <NaoAutorizado />
  }

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        {/* <Route path={"*"} element={<Login />} /> */}
        <Route path={"/" && "*"} element={<Login />} />
        <Route
          path={"/aluno"}
          element={
            <AuthRoute>
              <HomeAluno />
            </AuthRoute>
          }
        />
        <Route
          path={"/secretaria"}
          element={
            <AuthAdmRoute>
              <HomeSecretaria />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/professor"}
          element={
            <AuthProfessorRoute>
              <ProfessorHome />
            </AuthProfessorRoute>
          } />
        <Route
          path={"/coordenador"}
          element={
            <AuthCoordenadorRoute>
              <CoordenadorHome />
            </AuthCoordenadorRoute>
          } />
        <Route
          path={"/vercarterinhasecretaria"}
          element={
            <AuthAdmRoute>
              <VerCarterinha />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/cadastroaluno"}
          element={
            <AuthAdmRoute>
              <CadastroAluno />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/desativaraluno"}
          element={
            <AuthAdmRoute>
              <DesativarAluno />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/vercarterinhas"}
          element={
            <AuthAdmRoute>
              <VerCarterinhas />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/gerarcarteirinhas"}
          element={
            <AuthAdmRoute>
              <GerarCarteirinhas />
            </AuthAdmRoute>
          }
        />
        <Route
          exact
          path="/Ajuda"
          element={() => {
            window.location.href = "https://alunos.tawk.help"
          }}
        />
        <Route
          path={"/visualizaralunos"}
          element={
            <AuthAdmRoute>
              <VisualizarAlunos />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/visualizarusuarios"}
          element={
            <AuthAdmRoute>
              <VisualizarUsuarios />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/alunosemfoto"}
          element={
            <AuthAdmRoute>
              <AlunoSemFoto />
            </AuthAdmRoute>
          }
        />
        <Route
          path={"/progressao-parcial-coordenador"}
          element={
            <AuthCoordenadorRoute>
              <ProgressaoParcialCoordenador />
            </AuthCoordenadorRoute>
          }
        />
        <Route
          path={"/progressao-parcial-professor"}
          element={
            <AuthProfessorRoute>
              <ProgressaoParcialProfessor />
            </AuthProfessorRoute>
          } />
        <Route
          path={"/progressao-parcial"}
          element={
            <AuthRoute>
              <ProgressaoParcialAluno />
            </AuthRoute>
          } />
        {/* <Route
          path={"/alunosdesativados"}
          element={
            <AuthAdmRoute>
              <AlunoSemFoto />
            </AuthAdmRoute>
          }
        /> */}
        <Route
          path={"/v/:id"}
          element={
            <DadosPublicos />
          }
        />
      </Routes>
      <Globalstyles />
    </BrowserRouter>
  )
}

export default App
